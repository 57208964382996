import React from "react";

import { NoticiasLayout, Subtitle, Link } from "./styles";
import Card from "./Card";

const noticias = [
	{
		title: "Lorem Ipsum",
		extract:
			"Semper ac dolor vitae accumsan. interdum hendrerit lacinia. Semper dolor vitae accumsan. Aenean ac tortor at tellus feugiat.",
		date: "22 April 2016",
		img: "assets/static/Img_noticias_01.png",
	},
	{
		title: "Lorem Ipsum",
		extract:
			"Semper ac dolor vitae accumsan. interdum hendrerit lacinia. Semper dolor vitae accumsan. Aenean ac tortor at tellus feugiat.",
		date: "22 April 2016",
		img: "assets/static/Img_noticias_02.png",
	},
	{
		title: "Lorem Ipsum",
		extract:
			"Semper ac dolor vitae accumsan. interdum hendrerit lacinia. Semper dolor vitae accumsan. Aenean ac tortor at tellus feugiat.",
		date: "22 April 2016",
		img: "assets/static/Img_noticias_03.png",
	},
];

export default function Noticias({ noticias }) {
	return (
		<NoticiasLayout>
			<div className="Noticias_eventos">
				<h1>Noticias y eventos</h1>
				{noticias?.length ? (
					noticias
						.slice(0, 3)
						.map((noticia, i) => <Card key={i} {...noticia} />)
				) : (
					<img src="/assets/img/proximamente.png" alt="" />
				)}
			</div>
			<div className="Noticias_encuentranos">
				<h1>Encuéntranos</h1>
				<img src="/assets/static/Img_encuentranos.png" alt="" />
				<Link href="https://goo.gl/maps/cF8zz5XcHssKmCyH6" target="_blank">
					Aquí nos encontramos
				</Link>
			</div>
			<div className="Noticias_info">
				<div className="Noticias_info-card">
					
					{/* <a href="https://api.whatsapp.com/send?phone=523310648045&text=Me%20interesa%20saber%20m%C3%A1s%20sobre%20sus%20servicios."> */}
						<img src="/assets/static/icon-whatsapp-100.png" alt="" />
						<h1>Whatsapp</h1>
						<h3>33 3218 5132</h3>
					{/* </a> */}
				</div>
				<div className="Noticias_info-card">
					<img src="/assets/static/Dirección.svg" alt="" />
					
					
					<h2>Hotel Boutique</h2>
					<h3>
						Privada de Fabiana no.100, Carretera Federal no.15 Gdl-Morelia,
						Kilómetro 86, San Cristóbal Zapotitlán, Jocotepec, Jalisco.
					</h3>
				</div>
				<div className="Noticias_info-card">
					<img src="/assets/static/Icn_correo.svg" alt="" />
					<h1>Correo</h1>
					<h3>reservaciones@quintafabiana.com</h3>
				</div>
			</div>
		</NoticiasLayout>
	);
}
