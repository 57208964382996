import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "../../elements/Button";
import s from "./styles";

import useToggle from "../../hooks/useToggle";

export default function EventModal(props) {
	const {
		title = "",
		id = "",
		date = new Date(),
		name = "",
		email = "",
		phone = "",
		enabled = "0",
		type,
		max_assistenten,
		attendance,
		img,
		detail,
		services,
		visibility = "PRIVADO",
		is_cancelled,
		addType,
		onSave,
		onHide,
	} = props;

	const { register, handleSubmit, errors } = useForm();
	const [startDate, setStartDate] = useState(new Date(date));
	const [isPaid, toggleIsPaid] = useToggle(enabled === "1");
	const [isCancelled, toggleIsCancelled] = useToggle(is_cancelled === "1");

	const onSubmit = (data) => {
		console.log(data);
		// get img file
		const imgInput = document.getElementById("event_modal_img");
		const img = imgInput && imgInput.files && imgInput.files[0];

		onSave({
			...data,
			id,
			enabled: isPaid ? "1" : "0",
			is_cancelled: isCancelled ? "1" : "0",
			img,
			date: startDate.toISOString().slice(0, 10),
		});
		onHide();
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{ opacity: "1" }}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
			</Modal.Header>
			<s.Modal_body>
				{" "}
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<s.H1>Evento</s.H1>
						<Row>
							<Col className="text-left">
								<Form.Label>Fecha</Form.Label> <br />
								<DatePicker
									dateFormat="yyyy-MM-dd"
									selected={startDate}
									onChange={(date) => setStartDate(date)}
								/>
							</Col>
							{!addType && (
								<React.Fragment>
									<Col className="text-center">
										<Form.Label>Pago</Form.Label>
										<Form.Check
											type="switch"
											id="switch_paid"
											name="enabled"
											label={isPaid ? "Pagado" : "Pendiente"}
											ref={register}
											checked={isPaid}
											onClick={toggleIsPaid}
										/>
									</Col>
									<Col className="text-center">
										<Form.Label>¿Evento Cancelado?</Form.Label>
										<Form.Check
											type="switch"
											id="switch_cancelled"
											name="is_cancelled"
											label={isCancelled ? "Cancelado" : "Activo"}
											ref={register}
											checked={isCancelled}
											onClick={toggleIsCancelled}
										/>
									</Col>
								</React.Fragment>
							)}
						</Row>

						<Form.Label>Nombre</Form.Label>
						<Form.Control
							name="title"
							defaultValue={title}
							ref={register({ required: true, maxLength: 64 })}
						/>
						{errors.title?.type === "required" && (
							<s.Error type="invalid">
								El título del evento es obligatorio.
							</s.Error>
						)}
						{errors.title?.type === "maxLength" && (
							<s.Error type="invalid">
								La longitud máxima es de 64 caracteres.
							</s.Error>
						)}

						<Form.Label>Tipo</Form.Label>
						<Form.Control
							as="select"
							defaultValue={type}
							name="type"
							ref={register}
						>
							{services.map((service) => (
								<option key={service.id} value={service.id}>
									{service.name}
								</option>
							))}
						</Form.Control>

						{!addType && (
							<React.Fragment>
								<Form.Label>Visibilidad</Form.Label>
								<Form.Control
									as="select"
									defaultValue={visibility}
									name="visibility"
									ref={register}
								>
									<option value="PUBLICO">Publico</option>
									<option value="PRIVADO">Privado</option>
								</Form.Control>
								<Form.Text>
									Los eventos públicos serán visibles en la web principal.
								</Form.Text>
							</React.Fragment>
						)}

						<Form.Label>Número de asistentes</Form.Label>
						<Form.Control
							name="max_assistenten"
							defaultValue={max_assistenten}
							ref={register({ required: true, pattern: /^[0-9]*$/i })}
						/>
						{errors.max_assistenten?.type === "pattern" && (
							<s.Error type="invalid">Ingrese un número válido.</s.Error>
						)}

						{!addType && (
							<React.Fragment>
								<Form.Text>Asistentes confirmados: {attendance}</Form.Text>

								<Form.Label>Detalle</Form.Label>
								<Form.Control
									as="textarea"
									name="detail"
									defaultValue={detail}
									ref={register}
								/>

								{/* <Form.Label>Tags</Form.Label>
								<Form.Control name="tags" defaultValue={tags} ref={register} />
								<Form.Text className="text-muted">
									Separados por coma.
								</Form.Text> */}

								<Form.Label>Imagen</Form.Label>
								<Row className="align-items-center">
									<Col xs={2}>
										<img src={img} alt="" width="100%" />
									</Col>
									<Col xs={10}>
										<Form.File id="event_modal_img">
											<Form.File.Input />
										</Form.File>
									</Col>
								</Row>
							</React.Fragment>
						)}
					</Form.Group>

					<Form.Group>
						<s.H1>Cliente</s.H1>
						<Form.Label>Nombre</Form.Label>
						<Form.Control
							name="name"
							defaultValue={name}
							ref={register({ required: true })}
						/>
						{errors.name?.type === "required" && (
							<s.Error type="invalid">El nombre es obligatorio.</s.Error>
						)}

						<Form.Label>Correo</Form.Label>
						<Form.Control
							name="email"
							defaultValue={email}
							ref={register({ required: true, pattern: /^\S+@\S+$/i })}
						/>
						{errors.email?.type === "required" && (
							<s.Error type="invalid">
								La cuenta de correo es obligatoria.
							</s.Error>
						)}
						{errors.email?.type === "pattern" && (
							<s.Error type="invalid">
								Ingrese una cuenta de correo válida.
							</s.Error>
						)}

						<Form.Label>Teléfono</Form.Label>
						<Form.Control
							name="phone"
							defaultValue={phone}
							ref={register({
								required: true,
								minLength: 6,
								pattern: /^[0-9]*$/i,
							})}
						/>
						{errors.phone?.type === "required" && (
							<s.Error type="invalid">El telefono es obligatorio.</s.Error>
						)}
						{errors.phone?.type === "minLength" ||
							(errors.phone?.type === "pattern" && (
								<s.Error type="invalid">
									Ingrese un número de teléfono válido.
								</s.Error>
							))}
					</Form.Group>

					<Modal.Footer className="justify-space-between">
						<Button onClick={onHide} type="button" outline>
							Cancelar
						</Button>
						<Button type="submit">Guardar</Button>
					</Modal.Footer>
				</Form>
			</s.Modal_body>
		</Modal>
	);
}
