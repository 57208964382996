import styled from "styled-components";

export const Layout = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	padding: 2em;
	width: 100%;
`;

export const Wrapper = styled.div`
	width: 100%;
	max-width: 1300px;
`;
