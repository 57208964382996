import React from "react";

import Select from "react-select";

export default ({
	options = ["vacio"],
	label = "",
	isMulti,
	onChange,
	defaultIndex,
}) => (
	<Select
		isMulti={isMulti}
		onChange={onChange}
		defaultValue={!isNaN(defaultIndex) && options[defaultIndex]}
		label={label}
		options={options}
		theme={(theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary: "#85496c",
				primary75: "#D1B2C4",
				primary25: "#D1B2C4",
			},
		})}
	/>
);
