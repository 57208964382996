import React, { useState, memo } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import Modal from "react-bootstrap/Modal";
import Button from "../../elements/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import s from "./styles";

import api from "../../api";

let normas;
let politicas;
        fetch("https://quintafabiana.com/api/get_politicas",{
			method: 'POST', // or 'PUT'
			//body: JSON.stringify(data), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json'
			}
		})
        .then(res => res.json())
        .then(
          (result) => {
            normas=result.data.normas
			politicas=result.data.politicas
          },
          // Nota: es importante manejar errores aquí y no en 
          // un bloque catch() para que no interceptemos errores
          // de errores reales en los componentes.
          (error) => {
            normas=''
			politicas=''
          }
        )

function BookingEventModal(props) {
	const {
		title = "",
		date = new Date(),
		name = "",
		email = "",
		phone = "",
		type,
		max_assistenten,
		services,
		onHide,
	} = props;

	const { register, handleSubmit, errors } = useForm();
	const [startDate, setStartDate] = useState(new Date(date));
	const [confirmModal, setConfirmModal] = useState("");

	const onSubmit = async (event) => {
		const formData = new FormData();

		Object.keys(event).forEach((key) => {
			formData.append(key, event[key]);
		});

		formData.append("date", startDate.toISOString().slice(0, 10));

		await api.Bookings.create(formData);

		setConfirmModal("ok");
	};

	if (confirmModal) {
		return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ opacity: "1" }}
			>
				<s.Modal_body>
					<s.Modal_icon>
						<i class="fas fa-check"></i>
					</s.Modal_icon>
					<s.Modal_tile>Solicitud enviada</s.Modal_tile>
					<s.Modal_text>
						Gracias por su preferencia. <br />
						En breve nos pondremos en contacto para continuar con el proceso.{" "}
						<br />
					</s.Modal_text>
					<s.Modal_text_footer>
						Contamos con terminal física en nuestra oficina y terraza para el
						pago con tarjetas.
					</s.Modal_text_footer>
					<s.Modal_button onClick={onHide}>Aceptar</s.Modal_button>
				</s.Modal_body>
			</Modal>
		);
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{ opacity: "1" }}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Solicita tu reservación
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div> 
					<p dangerouslySetInnerHTML={{__html: normas}} />
				</div>
				<hr></hr>
				<hr></hr>
				<div>
				<p dangerouslySetInnerHTML={{__html: politicas}} />
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default memo(BookingEventModal);
