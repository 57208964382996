import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

export const NoticiasLayout = styled.div`
	display: grid;
	grid-template-columns: 7fr 3fr;
	column-gap: 1em;

	width: 90%;
	max-width: 1300px;
	margin: 0 auto;
	padding: 3rem 0;

	h1 {
		font-size: 32px;
		font-family: "Exo", sans-serif;
		color: var(--color-primary);
	}

	.Noticias_encuentranos {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		img {
			margin: 2.5em 0;
			width: 100%;
			max-width: 370px;
		}
	}

	.Noticias_eventos {
		h1 {
			font-weight: normal;
		}
	}

	.Noticias_eventos{
		img {
			margin: 2.5em 0;
			width: 100%;
		}
	}

	.Noticias_info {
		grid-column: 1 / span 2;
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		justify-items: center;
		gap: 1rem;

		width: 100%;
		margin: 5em 0 0;

		&-card {
			text-align: center;
			a {
				text-decoration: none;
			}
			img {
				height: 58px;
				margin: 1.5rem;
			}
			h1 {
				color: var(--color-primary);
				font-weight: 700;
				font-family: "Raleway", sans-serif;
				font-size: 18px;
				text-transform: uppercase;
			}
			h2 {
				color: var(--color-primary);
				font-weight: 700;
				font-family: "Raleway", sans-serif;
				font-size: 18px;
				text-transform: uppercase;
				margin-top: 1rem;
			}
			h3 {
				font-size: 20px;
				display: block;
				color: var(--color-secondary);
				font-family: "Cormorant Garamond", serif;
			}
		}
	}

	@media ${device.tablet} {
		grid-template-columns: 1fr;

		.Noticias_info {
			grid-column: 1;
			grid-template: auto / 1fr;
		}

		.Noticias_encuentranos {
			margin-top: 2rem;
		}
	}

	@media ${device.mobileL} {
		.Noticias_info {
			flex-direction: column;
			&-card {
				margin: 1em 0;
			}
		}
	}
`;

export const CardLayout = styled.div`
	display: grid;
	grid-template-columns: 125px 1fr;
	gap: 1em;

	margin: 2em 0;

	img {
		width: 100%;
		height: 100%;
		border-radius: 5px;

		object-fit: cover;
	}

	.Card_content {
		padding-right: 1em;
		h1 {
			font-size: 1.3em;
			margin: 0 0 0.5em;
			font-family: "Arial", sans-serif;
		}
		p {
		}

		h6 {
			margin: 6px 0 0 0;
			font-size: 17px;
			color: #868686;
			display: inline-block;
		}
	}

	@media ${device.tablet} {
		.Card_content {
			padding-right: 2em;
		}
	}
`;

export const Subtitle = styled.h6`
	margin-top: 1.5rem;

	font-size: 1rem;
	color: gray;
	font-weight: bold;
`;

export const Link = styled.a`
	width: max-content;
	padding: 14px 2.5%;
	border: solid 1px #85496c;
	border-radius: 3px;
	text-decoration: none;

	color: ${(props) => (props.outline ? "#85496c" : "#fff")};
	text-transform: uppercase;
	font-size: 14px;

	background: ${(props) => (props.outline ? "#fff" : "#85496c")};
	outline: none;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	transition: 0.1s ease-in;

	${(props) => props.disabled && `opacity: 0.7;`};

	&:hover {
		background: rgba(133, 73, 108, 0.8);
		color: #fff;
		text-decoration: none;
	}
`;
