import React, { useContext } from "react";

import Menu from "./Menu";
import Submenu from "./Submenu";
import { HeaderBar, HeaderLogo, Nav, HeaderReservation } from "./styles";
import { staticContext } from "../../contexts/Static.context";
import BookingEventModal from "../BookingEventModal";
import BookingResHabitaModal from "../BookingResHabitaModal";
import useToggle from "../../hooks/useToggle";
import ModalSpa from "../../pages/Spa/index";

import { Li } from './styles';
import { NavLink } from 'react-router-dom';
import Spa from "../../pages/Spa/index";

function Header() {
	const { state } = useContext(staticContext);
	const [isModalActive, toggleIsModalActive] = useToggle(false);

	const [isModalSpaActive, toggleIsModalSpaActive] = useToggle(false);

	const { events: eventsList = [] } = state;
	const events = eventsList.map((event) => ({
		text: event.name,
		href: `/services/${event.id}`,
	}));

	return (
		<React.Fragment>
			<HeaderBar className="Header">
				<HeaderLogo src="/assets/img/logo_quinta_fabiana_nuevo_color.svg" alt="" />
				<Nav className="Header_links">
					<Menu href="/" text="Home" exact />
					<Menu href="/services" text="Servicios">
						<Submenu items={events} />
					</Menu>
					{/* <Menu href="/events" text="Eventos">
						<Submenu items={events} />
					</Menu> */}
					<Menu href="/events" text="Eventos" exact />
					<Menu href="/offers" text="Promociones" exact />
					<Menu href="/gallery" text="Galería" exact />
					<Li>
					<a onClick={toggleIsModalSpaActive}>
							Spa
							<i className="fas fa-angle-right" />
						</a>
						{/* {props.children ? props.children : ''} */}
					</Li>
					{/* <ModalSpa href="/spa" text="Spa" exact>
						<a onClick={toggleIsModalActive}>
							Spa
							<i className="fas fa-angle-right" />
						</a>
					</ModalSpa> */}
				
					
				</Nav>
				<div className="Header_phone" />
				<HeaderReservation>
					<a target="_blank" href="https://direct-book.com/properties/quintafabianadirect">
						Reservar Habitación
						<i className="fas fa-angle-right" />
					</a>
				</HeaderReservation>
			</HeaderBar>

			{isModalSpaActive && (
				<Spa
				addType
				services={state.allServices}
				show={isModalSpaActive}
				onHide={toggleIsModalSpaActive}
				onSave={null}
			></Spa>
			)}

			{isModalActive && (
				
				<BookingResHabitaModal
					addType
					services={state.allServices}
					show={isModalActive}
					onHide={toggleIsModalActive}
					onSave={null}
				></BookingResHabitaModal>
			)}
		</React.Fragment>
	);
}

export default Header;
