import styled from 'styled-components';

export default styled.h1`
	width: 100%;
	text-align: center;
	font-family: 'Exo', sans-serif;
	color: #85496c;
	font-weight: 300;
	text-transform: uppercase;
`;
