import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";

import LoadingOverlay from "../../components/LoadingOverlay";
import Header from "../../components/Header";
import BannerLite from "../../components/BannerLite";
import Gallery from "../../components/Gallery";
import Footer from "../../components/Footer";
import api from "../../api";

import { staticContext } from "../../contexts/Static.context";

import { Wrapper } from "./styled.components";

import ButtonsBar from "./ButtonsBar";
const LandingPage = styled.div`
	display: grid;
	position: relative;
	grid-template-rows: auto 1fr;

	height: 100%;
	min-height: 100vh;

	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;

	font-size: 14px;
`;

export default function Landing() {
	const { state, getStaticData } = useContext(staticContext);
	const [galleries, setGalleries] = useState([]);
	const [activeGallery, setActiveGallery] = useState(0);

	async function getGalleries() {
		try {
			const response = await api.Gallery.index();

			setGalleries(response.data);
		} catch (error) {}
	}

	useEffect(() => {
		if (state.events.length <= 0) {
			getStaticData();
		}
		if (galleries.length <= 0) {
			getGalleries();
		}
	}, [state, getStaticData]);

	if (state.isLoading) {
		return <LoadingOverlay />;
	}

	const { name, imgs } = galleries[activeGallery] || { name: "", imgs: [] };

	const src = imgs.map((img, i) => ({
		src: img.imgUrl,
		width: !(i % 5) ? 2 : i % 3 ? 1 : 2,
		height: 1,
	}));

	return (
		<LandingPage>
			<Header />
			<BannerLite messages={state.bannerMsgs} images={state.bannerImgs} />
			<Wrapper>
				<ButtonsBar
					galleries={galleries}
					onChangeGallery={setActiveGallery}
					activeGallery={activeGallery}
				></ButtonsBar>
				<Gallery imgs={src}></Gallery>
			</Wrapper>
			<Footer />
		</LandingPage>
	);
}
