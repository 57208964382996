import styled from "styled-components/macro";

const H1 = styled.h1`
	display: block;
	width: 100%;
	padding: 0.2rem;

	color: gray;
	font-size: 1.5rem;
	text-align: center;
	background: #eee;
`;

const Modal_body = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	height: 100%;
	padding: 2rem;
	padding-top: 0;
	margin: 0;
`;

const Modal_icon = styled.div`
	width: 4rem;
	height: 4rem;
	margin-top: -2rem;
	border-radius: 50%;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

	text-align: center;
	line-height: 4rem;
	font-size: 2rem;
	color: white;

	background: rgb(126, 200, 50);
`;

const Modal_tile = styled.h2`
	margin: 1rem 0;

	font-size: 2rem;
	color: gray;
`;

const Modal_text = styled.p`
	padding: 2rem 0;

	color: gray;
	font-size: 1.5rem;
	text-align: center;
`;

const Modal_text_footer = styled.p`
	border-top: 2px solid gray;

	font-family: '"Arial", sans-serif';
	color: gray;
	font-size: 0.8rem;
	text-align: center;
`;

const Modal_button = styled.button`
	width: 100%;
	padding: 0.4rem;

	color: white;
	font-weight: bold;
	background: rgb(126, 200, 50);

	&:hover {
		opacity: 0.8;
	}
`;

const StepContent = styled.div`
	label {
		margin-top: 1rem;
	}
`;

const EventDetail = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 1rem;
`;

const Title = styled.h2`
	color: #6b6b6b;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
`;

const Subtitle = styled.h3`
	color: #c6c6c6;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 16px;
`;

const Error = styled.h6`
	margin-top: 5px;

	color: var(--color-primary);
	font-weight: bold;
	text-transform: uppercase;
	font-size: 9px;
`;

export default {
	H1,
	Modal_body,
	Modal_icon,
	Modal_tile,
	Modal_text,
	Modal_text_footer,
	Modal_button,
	StepContent,
	Title,
	Subtitle,
	EventDetail,
	Error,
};
