import React, { useState } from "react";

import { FooterLayout } from "./styles";
import useToggle from "../../hooks/useToggle";
import BookingPoliticasModal from "../BookingPoliticasModal";

export default function Footer({ services = [] }) {
	const [startDate, setStartDate] = useState(new Date());
	const [activeService, setActiveService] = useState("");
	const [isToggled, toggleInput] = useToggle(false);
	const [isModalActive, toggleIsModalActive] = useToggle(false);
	const [isModalReservaActive, toggleIsModalReservaActive] = useToggle(false);
	return (
		<FooterLayout>
			<div className="Footer_content">
				<div className="Footer_content-about">
					<h1>Sobre Quinta Fabiana</h1>
					<p>
						Dentro de la Rivera de Chapala en la zona de San Cristóbal
						Zapotitlán , combinamos la naturaleza y arquitectura ideal para
						eventos sociales.
					</p>
					<img src="https://quintafabiana.com/assets/img/logo_quinta_fabiana_nuevo.png" alt="" />
					<img src="https://cosy-places-luxe.com/wp-content/uploads//2021/05/Cosy-Places-white-white.svg" alt="" />
				</div>
				<div className="Footer_content-newsletter">
					{/* <h1>newsletter</h1>
					<p>¡Mantente en contacto, suscríbete!</p>
					<input type="text" /> */}
					<h2>Se aceptan todas las tarjetas de crédito.</h2>
					<div className="Footer_content-redes">
						<a
							href="https://www.facebook.com/quinta.fabiana.5"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-facebook" />
						</a>
						<a
							href="https://www.instagram.com/quintafabiana/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-instagram" />
						</a>
					</div>
				</div>
				<ul className="Footer_content-links">
					<h1>Enlaces directos</h1>
					<li>
						<a href="/">Home</a>
					</li>
					<li>
						<a href="/services">Servicios</a>
					</li>
					<li>
						<a href="/events">Eventos</a>
					</li>
					<li>
						<a href="/offers">Paquetes</a>
					</li>
					<li>
						<a href="/gallery">Galería</a>
					</li>
					<li>
						<a style={{ cursor: 'pointer' }} onClick={toggleIsModalActive}>Normas y políticas</a>
					</li>
				</ul>
			</div>
			<div className="Footer_copyright">
				<p>
					Copyright © 2021 Quinta Fabiana.{" "}
					<span>Todos los derechos reservados.</span>
				</p>
			</div>

			{isModalActive && (
				// 	<BookingEventModal
				// 	addType
				// 	date={startDate.toISOString().slice(0, 19)}
				// 	type={activeService}
				// 	services={services}
				// 	show={isModalActive}
				// 	onHide={toggleIsModalActive}
				// 	onSave={null}
				// ></BookingEventModal>
				<BookingPoliticasModal
					addType
					date={startDate.toISOString().slice(0, 19)}
					type={activeService}
					services={services}
					show={isModalActive}
					onHide={toggleIsModalActive}
					onSave={null}
				></BookingPoliticasModal>

			)}

		</FooterLayout>
	);
}
