import React from "react";

import sNextEvents from "./NextEvents.styles";

import months from "../../helpers/monthsEsp";

export default function NextEvents({ events = [], onClick = () => {} }) {
	return (
		<sNextEvents.Layout>
			<sNextEvents.Title>próximos eventos</sNextEvents.Title>

			{events.map((event) => {
				const [, month, day] = event.date.split("-");

				return (
					<sNextEvents.Event key={event.id} onClick={() => onClick(event)}>
						<sNextEvents.Date>
							<sNextEvents.Day>{day}</sNextEvents.Day>
							<sNextEvents.Month>{months[+month]}</sNextEvents.Month>
						</sNextEvents.Date>
						<sNextEvents.EventTitle>{event.title}</sNextEvents.EventTitle>
					</sNextEvents.Event>
				);
			})}
		</sNextEvents.Layout>
	);
}
