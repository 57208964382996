import React from 'react';
import { NavLink } from 'react-router-dom';

import { Li } from './styles';

export default function Menu(props) {
	return (
		<Li>
			<NavLink to={props.href} activeClassName="active" exact={props.exact}>
				{props.text}
			</NavLink>
			{props.children ? props.children : ''}
		</Li>
	);
}
