import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

export const Layout = styled.div`
	position: relative;

	width: 100%;
	height: 50vh;

	&::after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: "";
		background: rgb(0, 0, 0);
		background: linear-gradient(
			360deg,
			rgba(0, 0, 0, 0.7) 0%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(133, 73, 108, 1) 100%
		);
		z-index: 2;
	}
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const BannerText = styled.div`
	display: grid;
	grid-template: minmax(max-content, 45vh) min-content auto / 1fr;
	justify-content: left;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	padding: 2rem;

	color: white;

	img {
		max-width: 255px;
		justify-self: center;
		transition: 1s ease;
	}

	h1 {
		justify-self: left;
		align-self: center;
		font-size: 1.5rem;
		transition: 1s ease;
		text-transform: uppercase;
		text-shadow: rgba(0, 0, 0, 0.6) 2px 2px 10px;
	}

	h2 {
		color: white;
		margin: 1rem 0 0;
		justify-self: left;
		align-self: start;
		font-size: 1rem;
		transition: 1s ease;
		text-shadow: rgba(0, 0, 0, 0.6) 2px 2px 10px;
		font-family: "Cormorant Garamond", serif;
	}

	@media ${device.laptop} {
		grid-template: minmax(max-content, 30vh) min-content auto / 1fr;
	}

	@media ${device.tablet} {
		h1 {
			font-size: 1.5rem;
		}
	}

	@media ${device.mobileL} {
		img {
			max-width: 155px;
		}
		h1 {
			text-align: center;
			font-size: 1rem;
		}

		h2 {
			font-size: 0.8rem;
		}
	}
`;
