import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

export const OpinionCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;

	color: white;

	.OpinionCard {
		&_img {
			width: 135px;
			height: 135px;

			border-radius: 100%;
			border: solid 5px #fff;
		}
		&_name {
			margin: 1em 1em 0;

			color: var(--color-elements-secondary);
			font-family: Arial, Helvetica, sans-serif;
			font-size: 24px;
		}
		&_ocupation {
			font-size: 1.5em;
			margin: 0.5em 0;
			font-family: Arial, sans-serif;
		}
		&_comment {
			padding: 1em;
			font-size: 1.5em;
		}
		&_rating {
			display: flex;

			i {
				margin: 0 0.2em;

				font-size: 1.5em;
				color: #f4bc16;
			}
		}
	}
`;

export const OpinionesLayout = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	position: relative;

	width: 100%;
	height: max-content;
	padding: 4em 0 6em;
	box-sizing: border-box;

	background: rgba(0, 0, 0, 0.2) fixed no-repeat 35% 50%;
	background-size: cover;
	background-image: url("./assets/static/img_alberca-min.png");
	background-blend-mode: overlay;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgb(133, 73, 108);
		background: radial-gradient(
			circle,
			rgba(133, 73, 108, 0.4) 0%,
			rgba(144, 89, 119, 0.7) 100%
		);
	}

	.Opiniones_text {
		position: relative;
		z-index: 100;
		text-align: center;

		h1 {
			font-size: 100px;
			font-family: "Tangerine", cursive;
			color: #fff;
			text-transform: none;
			font-weight: normal;
		}

		h2 {
			font-weight: 700;
			font-family: "Arial", sans-serif;
			font-size: 48px;
			letter-spacing: 4px;
			margin: -6px 0 0 0;
			color: #fff;
			text-transform: capitalize;
		}
	}

	@media ${device.mobileL} {
		.Opiniones_text {
			h1 {
				font-size: 60px;
			}
			h2 {
				font-size: 28px;
			}
		}
	}
`;

export const OpinionesImg = styled.img`
	display: block;
	margin: 0.5em auto;
	font-weight: 700;
	font-family: "Arial", sans-serif;
	font-size: 48px;
	text-transform: uppercas;
	padding: 0 0 70px 0;

	@media ${device.mobileL} {
		padding: 0 0 20px 0;
	}
`;

export const CardsContainer = styled.div`
	position: relative;
	/* display: flex;
	flex-wrap: nowrap;
	flex-shrink: 0;
	width: 100%;
	max-width: 1300px;
	align-items: start;
	justify-content: center;
	box-sizing: border-box; */
`;
