import styled from "styled-components/macro";

const defaultImg =
	"https://demo.myeventon.com/wp-content/uploads/2016/08/Capture.png";

const Image = styled.div`
	height: 150px;
	width: 100%;

	background-image: url(${(props) => (props.src ? props.src : defaultImg)});
	background-color: #eee;
	background-size: cover;
	background-position: center;
`;

const Content = styled.div`
	display: grid;
	grid-template: auto / max-content 1fr;
	gap: 1.5rem;

	width: 100%;
	height: 100%;
	padding: 1rem;
	padding-bottom: 1rem;
	border-left: 5px solid var(--color-primary);

	transition: 0.2s ease-in;

	${(props) =>
		props.soldOut
			? `
		background: rgba(133, 73, 108, 0.2);
		background-image: repeating-linear-gradient(45deg, transparent, transparent 10px, 
			rgba(255,255,255,.5) 10px, rgba(255,255,255,.5) 20px);
		`
			: ""};
`;

const Date = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	width: max-content;

	color: #ababab;
`;

const Day = styled.h3`
	font-size: 2rem;
	font-weight: bold;
`;

const Month = styled.h6`
	font-size: 0.8rem;
	text-transform: uppercase;
`;

const Detail = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	height: 100%;

	color: #6b6b6b;
`;

const Title = styled.h2`
	color: #6b6b6b;
	font-size: 22px;
	text-transform: uppercase;
`;

const Layout = styled.div`
	width: 100%;
	max-width: 450px;
	max-height: 280px;
	border-radius: 8px;
	overflow: hidden;

	color: white;

	cursor: pointer;
	background: rgb(241, 241, 245);
	transition: 0.2s ease-in-out;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

	&:hover {
		transform: scale(0.99);
		opacity: 0.9;
	}

	&:hover > ${Content} {
		border-left-width: 10px;
	}
`;

const SoldOut = styled.div`
	padding: 4px 6px;
	border-radius: 5px;

	color: white;
	font-weight: bold;
	background: var(--color-primary);
`;

export default {
	Layout,
	Image,
	Content,
	Date,
	Day,
	Month,
	Detail,
	Title,
	SoldOut,
};
