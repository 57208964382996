import React from "react";

import Carousel from "./Carousel";
import Card from "./Card";
import { CardsContainer, OpinionesImg, OpinionesLayout } from "./styles";

export default function Opiniones({ items = [] }) {
	if (!items.length) items = opinionsDefault;

	return (
		<OpinionesLayout>
			<div className="Opiniones_text">
				<h1>Lo que dicen de nosostros</h1>
				<h2>Nuestros Clientes</h2>
				<OpinionesImg src="assets/static/Icn_HojasViña_blanca.png" alt="" />
			</div>
			<CardsContainer>
				<Carousel title="Carousel">
					{items
						.filter((i) => i.enabled === "1")
						.map((opinion, i) => (
							<Card key={i} {...opinion} />
						))}
				</Carousel>
			</CardsContainer>
		</OpinionesLayout>
	);
}
const opinionsDefault = [
	{
		imgUrl: "/assets/static/male1.jpg",
		name: "Armando Clouthier",
		ocupation: "Empresario",
		comment:
			"Es un excelente lugar para tomarse un respiro y pasar un fin de semana inolvidable.",
		rating: "5",
		enabled: "1",
	},
	{
		imgUrl: "/assets/static/male1.jpg",
		name: "Armando Clouthier",
		ocupation: "Empresario",
		comment:
			"Es un excelente lugar para tomarse un respiro y pasar un fin de semana inolvidable.",
		rating: "5",
		enabled: "1",
	},
	{
		imgUrl: "/assets/static/male1.jpg",
		name: "Armando Clouthier",
		ocupation: "Empresario",
		comment:
			"Es un excelente lugar para tomarse un respiro y pasar un fin de semana inolvidable.",
		rating: "5",
		enabled: "1",
	},
];
