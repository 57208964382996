import React from "react";
import styled from "styled-components";

import Carousel from "./Carousel";
import H1 from "../../elements/H1";
import PromoCard from "../PromoCard";
import { Proximamente } from "./components";

const PromosLayout = styled.div`
	width: 100%;
	height: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	box-sizing: border-box;
	padding: 3rem 0;

	p {
		width: 80%;
		display: inline-block;
		margin: 1em 0 4em;
	}
`;

const PromosImg = styled.img`
	display: block;
	margin: 0.5em 0;
`;

const CardsContainer = styled.div`
	position: relative;
	/* display: flex;
	flex-wrap: nowrap;
	flex-shrink: 0;
	width: 100%;
	max-width: 1300px;
	align-items: start;
	justify-content: center;
	box-sizing: border-box; */
`;

export default function Promociones({ items = [] }) {
	const cards = items.filter((i) => i.enabled === "1");

	if (cards.length >= 1 && cards.length < 3) {
		for (let i = 0; cards.length < 3; i++) {
			cards.push(cards[0]);
		}
	}

	return (
		<PromosLayout>
			<H1>PAQUETES Y PROMOCIONES HOTEL</H1>
			<p>Conoce nuestras increíbles promociones.</p>
			<PromosImg src="assets/static/Icn_HojasViña_gris.png" alt="" />
			{!cards.length ? (
				<React.Fragment>
					<CardsContainer className="" />
					<Carousel title="Carousel">
						<Proximamente>Próximamente</Proximamente>
						<Proximamente>Próximamente</Proximamente>
						<Proximamente>Próximamente</Proximamente>
					</Carousel>
				</React.Fragment>
			) : (
				<React.Fragment>
					<CardsContainer className="" />
					<Carousel title="Carousel">
						{cards.map((promo, i) => (
							<PromoCard key={i} {...promo} />
						))}
					</Carousel>
				</React.Fragment>
			)}
		</PromosLayout>
	);
}

const promosDefault = [
	{
		imgUrl: "/assets/static/Img_paquete_01.png",
		title: "",
		description:
			"Habla con uno de nuestros asesores para obtener nuestras promociones actuales",
		tags: "promociones,actuales",
		type: "",
		price: "",
		original_price: "",
		spec: "",
		enabled: "1",
	},
];
