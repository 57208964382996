import styled from "styled-components";

const Calendar = styled.div`
	padding: 2rem;

	text-align: left;

	&& h2 {
		/* text-transform: capitalize; */
		font-size: 1.5rem;
		margin-right: 2rem;
	}
`;

const Button = styled.button`
	padding: 0.5rem 1rem;
	border: none;
	border-radius: 0.25em;
	margin-bottom: 2rem;

	color: white;
	font-size: 0.8rem;
	font-weight: bold;
	background: var(--color-primary);

	&:hover {
		opacity: 0.8;
	}
`;

export default { Calendar, Button };
