import React, { useState, memo } from "react";
import { useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal";
// import Button from "../../elements/Button";
// import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import s from "./styles";

import api from "../../api";

function ModalSpa(props) {
	return (
        <>
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{ opacity: "1" }}
		>
			<Modal.Header closeButton>
			</Modal.Header>
			<Modal.Body>
                <img style={{width:'100%', height:'100%'}} src="/assets/img/banner_spa_v3.png"/>
			</Modal.Body>
		</Modal>
        </>
	);
}

export default memo(ModalSpa);
