import styled from "styled-components";

const Layout = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	/* max-width: 650px; */
`;

const Header = styled.div`
	position: relative;
	width: 100%;
	padding: 1rem;
	padding-bottom: 2rem;
	border-left: 5px solid var(--color-primary);
`;

const CloseBtn = styled.button`
	position: absolute;
	top: -1.25rem;
	right: -1.25rem;

	width: 2.5rem;
	height: 2.5rem;
	border-radius: 100%;
	border: none;

	color: #eee;
	background: #6b6b6b;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

	&:hover {
		background: #eee;
		color: #6b6b6b;
	}
`;

const Image = styled.img`
	width: 100%;
	max-height: 430px;
	object-fit: cover;
`;

const Title = styled.h2`
	color: #6b6b6b;
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
`;

const Subtitle = styled.h3`
	font-size: 0.8rem;
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;

	padding: 1rem 0;
	border-bottom: 1px solid #eee;

	& > :first-child {
		grid-row: 1 / span 2;
	}
`;

const Footer = styled.div`
	width: 100%;
	padding: 1rem;

	text-align: center;
`;

const Icon = styled.i`
	margin: 0 1rem;

	color: #6b6b6b;
	font-size: 1rem;
`;

export default {
	Layout,
	Header,
	CloseBtn,
	Image,
	Title,
	Subtitle,
	Row,
	Icon,
	Footer,
};
