import React, { useState, useEffect } from "react";

import LoadingOverlay from "../../components/LoadingOverlay";
import AdminGallery from "../../components/AdminGallery";
import useToggle from "../../hooks/useToggle";

import api from "../../api";
import ButtonsBar from "./ButtonsBar";
import Header from "./Header";
import Form from "./Form";

import { Layout, MainContent, Subtitle } from "./styled.components";

export default function Gallery() {
	const [galleries, setGalleries] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [activeGallery, setActiveGallery] = useState(0);
	const [addImgs, toggleAddImgs] = useToggle(false);
	const [addGallery, toggleAddGallery] = useToggle(false);

	function onDeleteImg(image) {
		const newImgs = galleries[activeGallery].imgs.filter(
			(img) => img.imgUrl !== image.imgUrl
		);

		const newGallery = { ...galleries[activeGallery], imgs: newImgs };

		const newGalleries = [
			...galleries.slice(0, activeGallery),
			newGallery,
			...galleries.slice(activeGallery + 1),
		];

		setGalleries(newGalleries);

		api.Gallery.deleteImg(image.id);
	}

	function onDelete() {
		const newGalleries = [
			...galleries.slice(0, activeGallery),
			//deleted one
			...galleries.slice(activeGallery + 1),
		];

		setGalleries(newGalleries);

		api.Gallery.delete(galleries[activeGallery].id);
	}

	async function handleUpdateGallery(e) {
		e.preventDefault();

		let formData = new FormData();

		for (var i = 0; i < e.target.imgs.files.length; i++) {
			let file = e.target.imgs.files[i];

			formData.append("imgs[" + i + "]", file);
		}

		e.target.name.value && formData.append("name", e.target.name.value);

		await api.Gallery.update(galleries[activeGallery].id, formData);

		getGalleries();
		toggleAddImgs();
	}

	async function handleCreateGallery(e) {
		e.preventDefault();

		let formData = new FormData();

		for (var i = 0; i < e.target.imgs.files.length; i++) {
			let file = e.target.imgs.files[i];

			formData.append("imgs[" + i + "]", file);
		}

		formData.append("name", e.target.name.value);

		await api.Gallery.create(formData);

		getGalleries();
		toggleAddGallery();
	}

	async function getGalleries() {
		try {
			const response = await api.Gallery.index();

			setGalleries(response.data);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		getGalleries();
	}, []);

	if (isLoading) {
		return <LoadingOverlay />;
	}

	// if (!galleries.length) {
	// 	return <LoadingOverlay />;
	// }

	const { name, imgs } = galleries[activeGallery] || { name: "", imgs: [] };
	let overly;

	if (addGallery) {
		overly = (
			<Form
				title="Agregar Nueva Galería"
				onSubmit={handleCreateGallery}
				toggle={toggleAddGallery}
			></Form>
		);
	} else if (addImgs) {
		overly = (
			<Form
				title="Agregar Imagenes"
				galleryName={name}
				onSubmit={handleUpdateGallery}
				toggle={toggleAddImgs}
			></Form>
		);
	}

	return (
		<Layout>
			<ButtonsBar
				galleries={galleries}
				activeGallery={activeGallery}
				onChangeGallery={setActiveGallery}
				onAdd={toggleAddGallery}
			></ButtonsBar>
			<MainContent>
				{galleries.length && (
					<Header
						title={name}
						onDelete={onDelete}
						onAdd={toggleAddImgs}
					></Header>
				)}
				{imgs.length ? (
					<AdminGallery imgs={imgs} onDelete={onDeleteImg}></AdminGallery>
				) : (
					<Subtitle>Galería Vacía</Subtitle>
				)}
			</MainContent>
			{overly}
		</Layout>
	);
}
