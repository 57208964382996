import styled from "styled-components";

const H1 = styled.h1`
	display: block;
	width: 100%;
	padding: 0.2rem;

	color: gray;
	font-size: 1.5rem;
	text-align: center;
	background: #eee;
`;

const Modal = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
`;

const Modal_body = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	height: 100%;
	padding: 2rem;
	padding-top: 1rem;
	margin: 0;

	& form {
		width: 100%;
	}

	& label {
		margin-top: 1rem;
	}
`;

const Error = styled.h6`
	margin-top: 5px;

	color: var(--color-primary);
	font-weight: bold;
	text-transform: uppercase;
	font-size: 9px;
`;

export default { H1, Modal, Error, Modal_body };
