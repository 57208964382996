import React from "react";

import {
	ImagesContainer,
	Image,
	DeleteIcon,
	ImageWrapper,
} from "./styled.components";

export default function AdminGallery({ imgs = [], onDelete }) {
	return (
		<ImagesContainer>
			{imgs.map((img, i) => (
				<ImageWrapper>
					<Image key={i} src={img.imgUrl} alt=""></Image>
					<DeleteIcon
						className="fas fa-trash"
						onClick={() => onDelete(img)}
					></DeleteIcon>
				</ImageWrapper>
			))}
		</ImagesContainer>
	);
}
