import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

export const NEXT = "NEXT";
export const PREV = "PREV";

export const Item = styled.div`
	text-align: center;
	padding: 100px;
	background-image: ${(props) => `url(${props.img})`};
	background-size: cover;
`;

export const CarouselContainer = styled.div`
	display: flex;
	transition: ${(props) => (props.sliding ? "none" : "transform 1s ease")};
	transform: ${(props) => {
		if (!props.sliding) return "translateX(calc(-50% - 20px))";
		if (props.dir === PREV) return "translateX(calc(2 * (-50% - 20px)))";
		return "translateX(0%)";
	}};

	/* Small devices */
	@media ${device.tablet} {
		transform: ${(props) => {
			if (!props.sliding) return "translateX(calc(-95% - 20px))";
			if (props.dir === PREV) return "translateX(calc(2 * (-95% - 20px)))";
			return "translateX(0%)";
		}};
	}
`;

export const Wrapper = styled.div`
	width: 100vw;
	max-width: 1300px;
	overflow: hidden;
	padding: 0 2rem;
	/* box-shadow: 5px 5px 20px 7px rgba(168, 168, 168, 1); */
`;

export const CarouselSlot = styled.div`
	display: flex;
	justify-content: center;
	flex: 1 0 100%;
	flex-basis: 50%;
	margin-right: 20px;
	order: ${(props) => props.order};

	/* Small devices */
	@media ${device.tablet} {
		flex-basis: 95%;
	}
`;

export const SlideButton = styled.button`
	width: 50px;
	height: 50px;
	color: #ffffff;
	font-family: Open Sans;
	font-size: 16px;
	font-weight: 100;
	padding: 10px;
	background-color: rgba(133, 73, 108, 0.4);
	border: none;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	margin-top: 20px;
	text-decoration: none;
	float: ${(props) => props.float};
	border-radius: 50%;
	margin: 1em;

	&::-moz-focus-inner {
		border: 0;
	}

	&:hover {
		background-color: rgba(133, 73, 108, 1);
	}

	&:active {
		position: relative;
		top: 1px;
		outline: 0;
	}
	&:focus {
		outline: 0;
	}

	@media ${device.mobileL} {
		display: none;
	}
`;

export const AppContainer = styled.div`
	font-family: sans-serif;
	text-align: center;
	width: 75%;
`;

export const ExtraInfo = styled.div`
	margin-top: 25px;
	display: inline-block;
`;

export const Code = styled.code`
	background-color: rgba(27, 31, 35, 0.05);
	border-radius: 3px;
	margin: 0;
	padding: 0.2em 0.4em;
`;

export const Proximamente = styled.h1`
	width: 100%;
	padding: 2rem 1rem;
	margin: 4rem 0 8rem;
	border-radius: 3px;

	color: gray;
	font-weight: bold;
	background: rgba(133, 73, 108, 0.2);
`;
