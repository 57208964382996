import React, { useState, memo } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import Modal from "react-bootstrap/Modal";
import Button from "../../elements/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import s from "./styles";

import api from "../../api";
import Menu from "./Menu";
import MetodoPago from './MetodoPago';
import ResumenPago from './ResumenPago';
import Paises from './Paises';
import Accordion from './Accordion';
import "./styles.css";

function BookingResHabitaModal(props) {
	const {
		title = "",
        date = new Date(),
		initDate = new Date(),
        finalDate = new Date(),
		name = "",
        apellidoPaterno = "",
        apellidoMaterno = "",
		email = "",
		telefcelular = "",
        teleffijo = "",
        direccion = "",
        ciudad = "",
        cp = "",
        pais = "",
        codigoPromo = "",
        fecha_entrada="",
        fecha_salida="",
        type,
		max_assistenten,
		services,
		onHide,
	} = props;

	const { register, handleSubmit, errors } = useForm();
	const [startDate, setStartDate] = useState(new Date(date));
    const [endDate, setEndDate] = useState(new Date(date));
	const [confirmModal, setConfirmModal] = useState("");
	const [active, setActive] = useState("Titulo1")

	const onSubmit = async (event) => {
        var f = document.getElementById('formulario');
		var formData = new FormData(f);

		Object.keys(event).forEach((key) => {
			formData.append(key, event[key]);
		});

		formData.append("date", startDate.toISOString().slice(0, 10));
        // for(let element of formData.values()){
        //     console.log(element)
        // }
        console.log(formData.values());
		await api.Bookings.create(formData);
        fetch('https://quintafabiana.com/api/reservar', {
            method: 'POST',
            body: formData
         })
         .then(function(response) {
            if(response.ok) {
                // alert(response.text);
            } else {
                
            }
         
         })
        
		setConfirmModal("ok");
	};

	if (confirmModal) {
		return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ opacity: "1" }}
			>
				<s.Modal_body>
					<s.Modal_icon>
						<i class="fas fa-check"></i>
					</s.Modal_icon>
					<s.Modal_tile>Solicitud enviada</s.Modal_tile>
					<s.Modal_text>
						Gracias por su preferencia. <br />
						En breve nos pondremos en contacto para continuar con el proceso.{" "}
						<br />
					</s.Modal_text>
					<s.Modal_text_footer>
						Contamos con terminal física en nuestra oficina y terraza para el
						pago con tarjetas.
					</s.Modal_text_footer>
					<s.Modal_button onClick={onHide}>Aceptar</s.Modal_button>
				</s.Modal_body>
			</Modal>
		);
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{ opacity: "1" }}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Solicita tu reservación
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>

			<Form.Group>
				<s.H1>Nuestras Suites</s.H1>
				<div style={{marginTop:'2%'}}>
					<Accordion/>
					{/* <Accordion title="Titulo2" active={active} setActive={setActive}/>
					<Accordion title="Titulo3" active={active} setActive={setActive}/>
					<Accordion title="Titulo4" active={active} setActive={setActive}/>
					<Accordion title="Titulo5" active={active} setActive={setActive}/>
					<Accordion title="Titulo6" active={active} setActive={setActive}/>
					<Accordion title="Titulo7" active={active} setActive={setActive}/>
					<Accordion title="Titulo8" active={active} setActive={setActive}/> */}
                </div>
			</Form.Group>
            
				<Form id="formulario" onSubmit={handleSubmit(onSubmit)}>
					<s.StepContent>
						<Form.Group>
							<s.H1>Datos de reservación</s.H1>
							<Row>
								<Col className="text-left">
									<Form.Label>Fecha de llegada:</Form.Label> <br />
									<DatePicker
                                        minDate={new Date("08/15/2021")}
                                        name="fecha_entrada"
										dateFormat="yyyy-MM-dd"
										selected={startDate}
										onChange={(date) => setStartDate(date)}
									/>
								</Col>

                                <Col className="text-left">
									<Form.Label>Fecha de salida:</Form.Label> <br />
									<DatePicker
                                        minDate={new Date("08/15/2021")}
                                        name="fecha_salida"
										dateFormat="yyyy-MM-dd"
										selected={endDate}
										onChange={(date) => setEndDate(date)}
									/>
								</Col>
							</Row>
							{/* <Form.Label>Agregue un nombre a su evento *</Form.Label>
							<Form.Control
								name="title"
								defaultValue={title}
								ref={register({ required: true, maxLength: 64 })}
							/>
							{errors.title?.type === "required" && (
								<s.Error type="invalid">
									El título del evento es obligatorio.
								</s.Error>
							)}
							{errors.title?.type === "maxLength" && (
								<s.Error type="invalid">
									La longitud máxima es de 64 caracteres.
								</s.Error>
							)} */}

							{/* <Form.Label>Seleccione el tipo de Habitación</Form.Label>
							<Form.Control
								as="select"
								defaultValue={type}
								name="type"
								ref={register}
							>
								{services.map((service) => (
									<option key={service.id} value={service.id}>
										
									</option>
								))}
							</Form.Control>

							<Form.Label>Ingrese el número de personas*</Form.Label>
                            <Form.Control
								as="select"
								defaultValue={type}
								name="type"
								ref={register}
							>
								{services.map((service) => (
									<option key={service.id} value={service.id}>
										
									</option>
								))}
							</Form.Control> */}

                            {/* <table>
                                <tr>
                                    <td>Column 1</td>
                                    <td>Column 2</td>
                                </tr>

                                <tr>
                                    <td>Column 3</td>
                                    <td>Column 4</td>
                                </tr>
                            </table> */}
                            
                            <div className="container">
                                <Menu/>
                            </div>

                            <Form.Label>Código de promoción</Form.Label>
							<Form.Control
								name="codigoPromo"
								defaultValue={codigoPromo}
								// ref={register({ required: true })}
							/>
							{/* {errors.name?.type === "required" && (
								<s.Error type="invalid">El nombre es obligatorio.</s.Error>
							)} */}

							{/* <Form.Control
								name="max_assistenten"
								defaultValue={max_assistenten}
								ref={register({ required: true, pattern: /^[0-9]*$/i })}
							/>
							{errors.max_assistenten?.type === "pattern" && (
								<s.Error type="invalid">Ingrese un número válido.</s.Error>
							)} */}
						</Form.Group>

						<Form.Group>
							<s.H1>Datos del huésped</s.H1>
							<Form.Label>Nombre *</Form.Label>
							<Form.Control
								name="name"
								defaultValue={name}
								ref={register({ required: true })}
							/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">El nombre es obligatorio.</s.Error>
							)}

                            <Form.Label>Apellido Paterno *</Form.Label>
							<Form.Control
								name="apellidoPaterno"
								defaultValue={apellidoPaterno}
								ref={register({ required: true })}
							/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">El apellido paterno es obligatorio.</s.Error>
							)}
                            
                            <Form.Label>Apellido Materno *</Form.Label>
							<Form.Control
								name="apellidoMaterno"
								defaultValue={apellidoMaterno}
								ref={register({ required: true })}
							/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">El apellido materno es obligatorio.</s.Error>
							)}

							<Form.Label>Correo *</Form.Label>
							<Form.Control
								name="email"
								defaultValue={email}
								ref={register({ required: true, pattern: /^\S+@\S+$/i })}
							/>
							{errors.email?.type === "required" && (
								<s.Error type="invalid">
									La cuenta de correo es obligatoria.
								</s.Error>
							)}
							{errors.email?.type === "pattern" && (
								<s.Error type="invalid">
									Ingrese una cuenta de correo válida.
								</s.Error>
							)}

							<Form.Label>Teléfono Fijo *</Form.Label>
							<Form.Control
								name="teleffijo"
								type="number"
								defaultValue={teleffijo}
								ref={register({
									required: true,
									minLength: 6,
									maxLength:15,
									// pattern: /^[0-9]*$/i,
									pattern: /^[0-9\b]+$/
									// pattern: /[0-9]*/
									
								})}
							/>
							{errors.teleffijo?.type === "required" && (
								<s.Error type="invalid">El telefono fijo es obligatorio.</s.Error>
							)}
							{errors.teleffijo?.type === "minLength" ||
								(errors.teleffijo?.type === "pattern" && (
									<s.Error type="invalid">
										Ingrese un número de teléfono válido.
									</s.Error>
								))}

                            <Form.Label>Teléfono Celular *</Form.Label>
							<Form.Control
								name="telefcelular"
								type="number"
								defaultValue={telefcelular}
								ref={register({
									required: true,
									minLength: 6,
									maxLength:10,
									// pattern: /^[0-9]*$/i,
									pattern: /^[0-9\b]+$/
								})}
							/>
							{errors.telefcelular?.type === "required" && (
								<s.Error type="invalid">El telefono celular es obligatorio.</s.Error>
							)}
							{errors.telefcelular?.type === "minLength" ||
								(errors.telefcelular?.type === "pattern" && (
									<s.Error type="invalid">
										Ingrese un número de teléfono válido.
									</s.Error>
								))}

                            <Form.Label>Dirección *</Form.Label>
							<Form.Control
								name="direccion"
								defaultValue={direccion}
								ref={register({ required: true })}
							/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">La dirección es obligatorio.</s.Error>
							)}

                            <Form.Label>Ciudad *</Form.Label>
							<Form.Control
								name="ciudad"
								defaultValue={ciudad}
								ref={register({ required: true })}
							/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">La ciudad es obligatorio.</s.Error>
							)}

                            <Form.Label>Código postal *</Form.Label>
							<Form.Control
								name="cp"
								type="number"
								defaultValue={cp}
								ref={register({ 
									required: true, 
									minLength: 5,
									maxLength:10,
									// pattern: /^[0-9]*$/i,
									pattern: /^[0-9\b]+$/
								})}
							/>
							{errors.cp?.type === "required" && (
								<s.Error type="invalid">El código postal es obligatorio.</s.Error>
							)}
							{errors.cp?.type === "minLength" ||
								(errors.cp?.type === "pattern" && (
									<s.Error type="invalid">
										Ingrese un código postal válido.
									</s.Error>
								))}

                            <Form.Label>País * </Form.Label>
							{/* <Form.Control
								name="pais"
								defaultValue={pais}
								ref={register({ required: true })}
							/> */}
                            <Paises name="pais" defaultValue={pais}/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">El país es obligatorio.</s.Error>
							)}
						</Form.Group>

                        {/* <Form.Group>
							<s.H1>Resumen del pago</s.H1>
                            <ResumenPago/>
						</Form.Group> */}


                        <Form.Group>
							<s.H1>Método de pago</s.H1>
							<Form.Label>Seleccione el método de pago</Form.Label>
							{/* <Form.Control
								as="select"
								defaultValue={type}
								name="type"
								ref={register}
							>
								{services.map((service) => (
									<option key={service.id} value={service.id}>
										
									</option>
								))}
							</Form.Control> */}
                            <MetodoPago/>
						</Form.Group>

					</s.StepContent>

					<Modal.Footer className="justify-content-space-between">
						<Button onClick={onHide} type="button" outline>
							Cancelar
						</Button>
						<Button type="submit">Enviar</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default memo(BookingResHabitaModal);
