import React from "react";

import { Layout, Image, BannerText } from "./styled.components";

export default function BannerLite({
	images = [],
	title = "Galería",
	messages = [{ title: "Galería", subtitle: "" }],
}) {
	return (
		<Layout>
			<BannerText>
				<img
					src="/assets/img/logo_quinta_fabiana_nuevo.png"
					className="animated zoomIn delay-1s"
					alt=""
				/>
				<h1>{title}</h1>
				<h2>{messages[0].title}</h2>
			</BannerText>
			<Image src={images[0]}></Image>
		</Layout>
	);
}
