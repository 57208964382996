import axios from "axios";

const baseURL = "https://quintafabiana.com/api/";

const api = axios.create({
	baseURL,
});

const EventTypes = {
	index: () => api.get("/event_types/"),
	single: (id) => api.get(`/event_types/${id}`),
};
const Reviews = {
	index: () => api.get("/reviews/"),
	single: (id) => api.get(`/reviews/${id}`),
};
const Offers = {
	index: () => api.get("/offers/"),
	single: (id) => api.get(`/offers/${id}`),
};
const BannerSlides = {
	index: () => api.get("/banner_slides/"),
	single: (id) => api.get(`/banner_slides/${id}`),
};
const Services = {
	index: () => api.get("/services/"),
	indexByType: (type) => api.get(`/services_by_type/${type}`),
	single: (id) => api.get(`/services/${id}`),
};
const Detalles = {
	index: () => api.get("/detalles/"),
	indexByType: (type) => api.get(`/detalles_by_type/${type}`),
	single: (id) => api.get(`/detalles/${id}`),
};
const Gallery = {
	index: () => api.get("/galleries/"),
	single: (id) => api.get(`/galleries/${id}`),
	create: (formData) =>
		api.post(`/galleries/`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
	delete: (id) => api.delete(`/galleries/${id}`),
	deleteImg: (id) => api.delete(`/galleries_imgs/${id}`),
	update: (id, formData) =>
		api.post(`/galleries_update/${id}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
};
const Bookings = {
	index: () => api.get("/bookings/"),
	indexByDate: (month, fullYear, type) =>
		api.get("/bookings_by_date/", { params: { month, fullYear, type } }),
	single: (id) => api.get(`/bookings/${id}`),
	nextEvents: (limit) =>
		api.get(`/bookings_next_events/`, { params: { limit } }),
	create: (formData) =>
		api.post(`/bookings/`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
	update: (id, formData) =>
		api.post(`/bookings_update/${id}`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
	delete: (id) => api.delete(`/bookings/${id}`),
};

const Attendance = {
	create: (formData) =>
		api.post(`/attendance/`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}),
};

export default {
	EventTypes,
	Reviews,
	Offers,
	BannerSlides,
	Services,
	Detalles,
	Gallery,
	Bookings,
	Attendance,
};
