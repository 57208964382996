import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import {Card, Button} from 'react-bootstrap'

        //const resp   = await fetch(`https://api.giphy.com/v1/gifs/random?api_key=${ apiKey }`);
        let listItems='';
        let cont=0;
        let result2;
        fetch("https://quintafabiana.com/api/get_habitaciones")
        .then(res => res.json())
        .then(
          (result) => {
            result2=result
          },
          // Nota: es importante manejar errores aquí y no en 
          // un bloque catch() para que no interceptemos errores
          // de errores reales en los componentes.
          (error) => {
            listItems=''
          }
        )



        function App() {  
     
              
            return (  
                <Accordion defaultActiveKey="0">
               
                    {result2.data.map((obj, index) => (  
              <Card >
              <Accordion.Toggle as={Card.Header} eventKey={index}>
              <h5 style={{fontSize:18, color:'black'}}>{obj.nombre_habitacion}</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index}>
              <Card.Body>
              <p style={{fontFamily:'sans-serif', fontSize:17}}>- Cuenta con: {obj.camas} cama King size</p>
                        <p style={{fontFamily:'sans-serif', fontSize:17}}>- Para un máximo de: {obj.huespedes} huéspedes</p>
                        <p style={{fontFamily:'sans-serif', fontSize:17}}>- Precio por noche: ${obj.precio_uno}.00*</p>
                       {/*<p style={{fontFamily:'sans-serif', fontSize:17}}>- Precio por noche de V-D: ${obj.precio_dos}.00*</p>*/}
                        <p style={{fontFamily:'sans-serif', fontSize:16, fontWeight:'bold', textAlign:'center'}}>{obj.texto_promocion}</p>
                        <div style={{}}>
                            <p style={{fontFamily:'sans-serif', fontSize:17, backgroundColor:'#DAF7A6', width:'40%'}}>- Precio por noche de L-J : ${obj.precio_uno_promocion}.00*</p>
                            <p style={{fontFamily:'sans-serif', fontSize:17, backgroundColor:'#DAF7A6', width:'40%'}}>- Precio por noche de V-D: ${obj.precio_dos_promocion}.00*</p>
                        </div>
                        <p style={{fontFamily:'sans-serif', fontSize:15, fontWeight:'bold', textAlign:'center'}}>Pesos Mexicanos MXN</p>
                        <p style={{fontFamily:'sans-serif', fontSize:13, fontWeight:'bold', textAlign:'center'}}>*Tarifas más impuestos, aplican restricciones.</p>
              </Card.Body>
              </Accordion.Collapse>
          </Card>
              
            ))}  
                
            </Accordion>
            );  
          }  
               
          export default App;  





/*

const AccordionComp = () => {



    return(
        <>
        <Accordion defaultActiveKey="0">
            <Card >
                <Accordion.Toggle as={Card.Header} eventKey="0">
                <h5 style={{fontSize:18, color:'black'}}>Suite Carignan</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <p style={{fontFamily:'sans-serif', fontSize:17}}>- Cuenta con: 1 cama King size</p>
                    <p style={{fontFamily:'sans-serif', fontSize:17}}>- Para un máximo de: 2 huéspedes</p>
                    <p style={{fontFamily:'sans-serif', fontSize:17}}>- Precio por noche de L-J : $3,400.00*</p>
                    <p style={{fontFamily:'sans-serif', fontSize:17}}>- Precio por noche de V-D: $3,700.00*</p>
                    <p style={{fontFamily:'sans-serif', fontSize:16, fontWeight:'bold', textAlign:'center'}}>Reserva del 20 de mayo al 16 de julio 2021 y recibe un 20% de descuento por Pre-Apertura para hospedarte a partir de Agosto 2021.</p>
                    <div style={{}}>
                        <p style={{fontFamily:'sans-serif', fontSize:17, backgroundColor:'#DAF7A6', width:'40%'}}>- Precio por noche de L-J : $2,720.00*</p>
                        <p style={{fontFamily:'sans-serif', fontSize:17, backgroundColor:'#DAF7A6', width:'40%'}}>- Precio por noche de V-D: $3,156.00*</p>
                    </div>
                    <p style={{fontFamily:'sans-serif', fontSize:15, fontWeight:'bold', textAlign:'center'}}>Pesos Mexicanos MXN</p>
                    <p style={{fontFamily:'sans-serif', fontSize:13, fontWeight:'bold', textAlign:'center'}}>*Tarifas más impuestos, aplican restricciones.</p>
                </Card.Body>
                </Accordion.Collapse>
            </Card>
            
            {listItems}
        </Accordion>
        </>

    )
}
export default AccordionComp


*/



// import React from 'react';

// const Accordion = ({title, active, setActive}) => {

//     return(
//         <div className="accordion">
//             <div className="accordionHeading">
//                 <div className="container">
//                     <h5>{title}</h5>
//                     <span onClick={() => setActive(title)}>X</span>
//                 </div>
//             </div>

//             <div className={(active === title ? "show" : "") + "accordionContent"}>
//                 <div className="container">
//                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default Accordion