import React from "react";

import { PromoCardLayout } from "./style";

export default function PromoCard(props) {
	const {
		imgUrl,
		title,
		description,
		tags = [],
		type,
		price,
		original_price,
		spec,
	} = props;
	return (
		<PromoCardLayout>
			<img src={imgUrl} alt="" />
			<div className="PromoCard_detail">
				<div className="PromoCard_detail-data">
					<h4>{title}</h4>
					<p>{description}</p>
					<ul className="PromoCard_detail-tags">
						{tags.split(",").map((tag, i) => (
							<li key={i}>{tag.trim()}</li>
						))}
					</ul>
				</div>
				<p>{type}</p>
				{/* <div className="PromoCard_promo">
					<p>{type}</p>
					<div className="PromoCard_promo-price">
						<h1>${price}</h1>
						<h3>${original_price}</h3>
						<h6>*{spec}</h6>
					</div>
					<button> Ver más</button>
				</div> */}
				<button class="sc-jrIDfS djSMrO"> Ver más</button>
			</div>
		</PromoCardLayout>
	);
}
