import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components/macro";

import LoadingOverlay from "../../components/LoadingOverlay";
import Header from "../../components/Header";
import BannerLite from "../../components/BannerLite";
import Footer from "../../components/Footer";
import GridEventCard from "../../components/GridEventCard";
import NextEvents from "../../components/NextEvents/";
import EventDetailModal from "../../components/EventDetailModal";

import { staticContext } from "../../contexts/Static.context";
import useToggle from "../../hooks/useToggle";
import { Wrapper, WrappNextEvents, WrappGridEvents } from "./styled.components";

const LandingPage = styled.div`
	display: grid;
	position: relative;
	grid-template-rows: auto minmax(100vh, 1fr) min-content min-content;

	height: 100%;
	min-height: 100vh;

	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;

	font-size: 14px;
`;

export default function Landing(props) {
	const { state, getStaticData } = useContext(staticContext);
	const [actiteEvent, setActiveEvent] = useState({});
	const [showModal, toggleShowModal] = useToggle(false);

	useEffect(() => {
		if (state.events.length <= 0) {
			getStaticData();
		}
	}, [state, getStaticData]);

	function handleClick(e) {
		const event = state.nextEvents.find((event) => event.id === e.id);

		if (event) {
			setActiveEvent(event);
			toggleShowModal();
		}
	}

	if (state.isLoading) {
		return <LoadingOverlay />;
	}

	const filterOptions =
		state.allServices &&
		state.allServices.map((service) => ({
			value: service.id,
			label: service.name,
		}));

	return (
		<React.Fragment>
			<LandingPage>
				<Header />
				<BannerLite
					title="Eventos"
					messages={state.bannerMsgs}
					images={state.bannerImgs}
				/>
				<Wrapper>
					<WrappNextEvents>
						<NextEvents
							events={state.nextEvents}
							onClick={handleClick}
						></NextEvents>
					</WrappNextEvents>

					<WrappGridEvents>
						<GridEventCard
							defaultFilter={props.match?.params?.id}
							filterOptions={filterOptions}
						></GridEventCard>
					</WrappGridEvents>
				</Wrapper>
				<Footer />
			</LandingPage>
			{showModal && (
				<EventDetailModal
					show={showModal}
					onHide={toggleShowModal}
					{...actiteEvent}
				/>
			)}
		</React.Fragment>
	);
}
