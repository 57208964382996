import styled from "styled-components/macro";

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: ${(props) => props.flexDirection || "column"};
	justify-content: ${(props) => props.justifyContent || "flex-start"};

	width: ${(props) => props.width || "100%"};
	padding: ${(props) => props.padding || "2em 1em 0"};
`;

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justifyContent || "flex-start"};

	padding: 1rem;
	margin: 0;

	text-align: center;
	cursor: pointer;
	background: none;
	border: none;

	&:hover {
		background: #eee;
	}

	&.active {
		background: var(--color-primary);
		color: white;
	}

	& i {
		margin: 0 1em;
	}

	${(props) => {
		if (props.danger) {
			return `
				background: none;
				border: 2px solid #d45d79;
				color: #d45d79;
				border-radius: 5px;
			`;
		}
		if (props.success) {
			return `
				color: white;
				background: #75b79e;
				border: 2px solid #75b79e;
				border-radius: 5px;
			`;
		}
	}}
`;

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-template-areas: "nextEvents gridEvents";
	justify-self: center;

	width: 100%;
	max-width: 1300px;

	@media (max-width: 768px) {
		grid-template-columns: auto;
		grid-template-rows: max-content 1fr;
		grid-template-areas: "gridEvents" "nextEvents";
	}
`;

export const WrappNextEvents = styled.div`
	grid-area: nextEvents;
`;

export const WrappGridEvents = styled.div`
	grid-area: gridEvents;
`;
