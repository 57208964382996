import api from "../../api";

export function dateReducer(state, action) {
	switch (action.type) {
		case "ADD_ONE_MONTH": {
			const newDate = new Date(state.date);
			newDate.setMonth(state.date.getMonth() + 1);
			return {
				...state,
				date: newDate,
			};
		}

		case "SUBTRACT_ONE_MONTH": {
			const newDate = new Date(state.date);
			newDate.setMonth(state.date.getMonth() - 1);
			return {
				...state,
				date: newDate,
			};
		}

		case "FETCH_MONTH_EVENT_START":
			return {
				...state,
				isLoading: true,
			};

		case "FETCH_MONTH_EVENT_SUCCESS":
			return {
				...state,
				isLoading: false,
				events: action.payload,
			};

		case "FETCH_MONTH_EVENT_FAILURE":
			return {
				...state,
				isLoading: false,
				events: [],
				error: action.payload,
			};

		default:
			return state;
	}
}

export function addOneMonth() {
	return {
		type: "ADD_ONE_MONTH",
	};
}

export function subtractOneMonth() {
	return {
		type: "SUBTRACT_ONE_MONTH",
	};
}

export function fetchMonthEventsStart() {
	return {
		type: "FETCH_MONTH_EVENT_START",
	};
}

export function fetchMonthEventsSuccess(events) {
	return {
		type: "FETCH_MONTH_EVENT_SUCCESS",
		payload: events,
	};
}

export function fetchMonthEventsFailure(error) {
	return {
		type: "FETCH_MONTH_EVENT_FAILURE",
		payload: error,
	};
}

export async function fetchMonthEvents(dispatch, month, year, type) {
	dispatch(fetchMonthEventsStart());

	try {
		const response = await api.Bookings.indexByDate(month, year, type);

		dispatch(fetchMonthEventsSuccess(response.data));
	} catch (error) {
		dispatch(fetchMonthEventsFailure(error.message));
	}
}

export const initialState = { date: new Date(), isLoading: false };
