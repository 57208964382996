import styled from "styled-components";

export default styled.button`
	width: max-content;
	padding: 14px 2.5%;
	border: solid 1px #85496c;
	border-radius: 3px;

	color: ${(props) => (props.outline ? "#85496c" : "#fff")};
	text-transform: uppercase;
	font-size: 14px;

	background: ${(props) => (props.outline ? "#fff" : "#85496c")};
	outline: none;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	transition: 0.1s ease-in;

	${(props) => props.disabled && `opacity: 0.7;`};

	&:hover {
		background: rgba(133, 73, 108, 0.8);
		color: ${(props) => props.outline && "#fff"};
	}
`;
