import React from "react";
import { ButtonsWrapper, Button, MainContentHeader } from "./styled.components";

export default function Header({ title, onDelete, onAdd }) {
	return (
		<MainContentHeader>
			<h1>{title}</h1>
			<ButtonsWrapper padding="0" width="max-content">
				<Button onClick={onAdd}>
					<i className="fas fa-plus"></i>
					Agregar Imagenes
				</Button>
				<Button onClick={onDelete}>
					<i className="fas fa-trash"></i>
					Borrar Galería
				</Button>
			</ButtonsWrapper>
		</MainContentHeader>
	);
}
