import React, { createContext, useReducer } from "react";

import api from "../api";

export const staticContext = createContext();

export default function StaticProvider(props) {
	const [state, dispatch] = useReducer(reducer, {
		isLoading: false,
		isBannerLoaded: false,
		events: [],
		offers: [],
		reviews: [],
		services: [],
		allServices: [],
		nextEvents: [],
		bannerImgs: undefined,
		bannerMsgs: undefined,
	});

	function reducer(state, action) {
		switch (action.type) {
			case "LOADING":
				return { ...state, isLoading: true };
			case "SET_STATIC_DATA":
				return {
					...action.payload,
					isLoading: false,
				};
			case "SET_BANNER_LOADED":
				return {
					...state,
					isBannerLoaded: true,
				};
			default:
				return state;
		}
	}

	async function getStaticData(params = {}) {
		if (state.isLoading) return;
		const {
			serviceType = null,
			allServices = true,
			eventTypes = true,
			offers = true,
			reviews = true,
			bannerSlides = true,
			nextEvents = true,
			force = false,
		} = params;

		dispatch({ type: "LOADING" });

		// Set promises
		const servicePromise =
			serviceType && !state.services[serviceType]
				? api.Services.indexByType(serviceType)
				: Promise.resolve(null);

		const allServicePromise =
			allServices && state.allServices.length <= 0
				? api.Services.index()
				: Promise.resolve({ data: state.allServices });

		const eventTypesPromise =
			eventTypes && state.events.length <= 0
				? api.EventTypes.index()
				: Promise.resolve({ data: state.events });

		const offersPromise =
			offers && state.offers.length <= 0
				? api.Offers.index()
				: Promise.resolve({ data: state.offers });

		const reviewsPromise =
			reviews && state.reviews.length <= 0
				? api.Reviews.index()
				: Promise.resolve({ data: state.reviews });

		const bannerSlidesPromise =
			bannerSlides && !state.bannerImgs
				? api.BannerSlides.index()
				: Promise.resolve(null);

		const nextEventsPromise =
			(nextEvents && state.nextEvents.length <= 0) || force
				? api.Bookings.nextEvents(10)
				: Promise.resolve({ data: state.nextEvents });

		const response = await Promise.all([
			eventTypesPromise,
			offersPromise,
			reviewsPromise,
			bannerSlidesPromise,
			servicePromise,
			allServicePromise,
			nextEventsPromise,
		]);

		let newBannerImgs = [];
		let newBannerMsgs = [];

		if (response[3]) {
			response[3].data.forEach(({ title, subtitle, imgUrl }) => {
				newBannerMsgs.push({ title, subtitle });
				newBannerImgs.push(imgUrl);
			});
		} else {
			newBannerImgs = state.bannerImgs;
			newBannerMsgs = state.bannerMsgs;
		}

		let isBannerLoaded = false;
		if (newBannerImgs.length) {
			newBannerImgs.forEach((img, i) => {
				const image = new Image();
				image.src = img;
				if (i === 0) {
					image.onload = () => dispatch({ type: "SET_BANNER_LOADED" });
				}
			});
			isBannerLoaded = true;
			
		} else {
			isBannerLoaded = true;
		}

		let newServices;

		if (serviceType && response[4]) {
			newServices = {
				...state.services,
				[serviceType]: response[4].data,
			};
		} else {
			newServices = state.services;
		}

		dispatch({
			type: "SET_STATIC_DATA",
			payload: {
				isLoading: false,
				isBannerLoaded,
				events: response[0].data,
				offers: response[1].data,
				reviews: response[2].data,
				bannerImgs: newBannerImgs,
				bannerMsgs: newBannerMsgs,
				services: newServices,
				allServices: response[5].data,
				nextEvents: response[6].data,
			},
		});
	}

	const values = { state, getStaticData };

	return (
		<staticContext.Provider value={values}>
			{props.children}
		</staticContext.Provider>
	);
}
