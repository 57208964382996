import React, {useState} from 'react';

const metodos = [
    {
        "nombre" : "Efectivo",
    },
    {
        "nombre" : "Tarjeta débito/crédito",
    },
    {
        "nombre" : "Deposito",
    },
]

function MetodoPago(){

    const [idMetodos, setIdMetodos] = useState(-1);   
    const handlerCargarMetodos = function (e) {
        const opcion = e.target.value;
        console.log(opcion)

        setIdMetodos(opcion);
    }

    return(
        <>
        <div className="row">
            <div style={{marginTop:'2%', marginBottom:'2%'}} className="col-6">
                <select style={{width:'100%'}} name="metodos" id="selmetodos" onChange={handlerCargarMetodos}>
                    <option value={-1}>Seleccione una opción: </option>
                    {
                        metodos.map((item, i) => (
                            <option key={"metodos" + i} value={item.nombre}>{item.nombre}</option>
                        ))
                    }
                </select>
            </div>
        </div>

       </>
    )
}

export default MetodoPago;