import styled from "styled-components/macro";

const Layout = styled.div`
	padding: 1rem;

	&::before {
		content: "";
		display: block;

		width: 100%;
		height: 50px;
		margin-bottom: 1.5rem;
		border-radius: 3px;

		background:  rgba(133, 73, 108, 0.5)
	}
`;

const Event = styled.div`
	display: flex;
	align-items: flex-start;

	margin: 1rem 0;

	cursor: pointer;
	transition: scale 0.3s;

	&:hover {
		transform: scale(1.03);
	}

	@media (max-width: 768px) {
		width: 50%;
		margin: 1rem auto;
	}
`;

const Date = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	grid-row: 1;
	grid-column: 1;

	width: 3.5rem;
	height: 3.5rem;
	padding: 0.5rem;
	border: 2px solid var(--color-primary);
	border-radius: 3px;
`;

const Day = styled.h3`
	margin: 0;

	color: var(--color-primary);
	font-size: 1rem;
	font-weight: bold;
`;

const Month = styled.h6`
	color: #6b6b6b;
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: bold;
`;

const Title = styled.h2`
	margin-bottom: 2rem;

	color: #6b6b6b;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	font-size: 16px;
`;

const EventTitle = styled(Title)`
	font-size: 12px;
	margin-left: 0.5rem;
`;

export default {
	Layout,
	Title,
	EventTitle,
	Event,
	Date,
	Day,
	Month,
};
