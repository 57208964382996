import React from 'react';
import { NavLink } from 'react-router-dom';

import { SubUl, SubLi } from './styles';

export default function Submenu(props) {
	return (
		<SubUl>
			{props.items.map((item, i) => (
				<SubLi key={i}>
					<NavLink to={item.href}>{item.text}</NavLink>
				</SubLi>
			))}
		</SubUl>
	);
}
