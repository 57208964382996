import styled from 'styled-components';

export const LoadingOverlayLayout = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: #85496c;

	h1 {
		font-size: 1.5rem;
		margin: 2rem 0;
	}
`;
