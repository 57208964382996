import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Landing from "../pages/Landing";
import Services from "../pages/Services";
import Detalles from "../pages/Detalles";
import SingleService from "../pages/SingleService";
import Paquetes from "../pages/Paquetes";
import AdminGallery from "../pages/AdminGallery";
import Calendar from "../pages/Calendar";
import Events from "../pages/Events";
import Gallery from "../pages/Gallery";
import Page404 from "../pages/Page404";
import ScrollToTop from "../components/ScrollToTop";

export default function Router() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Switch>
				<Route path="/" exact render={() => <Landing />} />
				<Route path="/services" exact render={() => <Services />} />
				{/* <Route path="/services" exact render={() => <Services />} /> */}
				<Route path="/detalles/:id" exact render={(props) => <Detalles {...props}/>} />
				<Route path="/services/:id" exact render={() => <SingleService />} />
				<Route path="/offers" exact render={() => <Paquetes />} />
				<Route path="/gallery" exact render={() => <Gallery />} />
				<Route
					path="/events/"
					exact
					render={(routeProps) => <Events {...routeProps} />}
				/>
				<Route
					path="/events/:id"
					exact
					render={(routeProps) => <Events {...routeProps} />}
				/>
				<Route path="/dash/gallery" exact render={() => <AdminGallery />} />
				<Route path="/dash/calendar" exact render={() => <Calendar />} />
				<Route render={() => <Page404 />} />
			</Switch>
		</BrowserRouter>
	);
}
