import React from 'react';
import SyncLoader from 'react-spinners/ClipLoader';

import { LoadingOverlayLayout } from './styles';

export default function LoadingOverlay() {
	return (
		<LoadingOverlayLayout>
			<SyncLoader size={50} color={'#85496c'} loading={true} />
			<h1>Cargando...</h1>
		</LoadingOverlayLayout>
	);
}
