import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";

import sEventDetailModal from "./EventDetailModal.styles";
import AttendanceModal from "../AttendanceModal";
import Button from "../../elements/Button";

import months from "../../helpers/monthsEsp";
import useToggle from "../../hooks/useToggle";
import { staticContext } from "../../contexts/Static.context";

export default function EventDetailModal(props) {
	const { state } = useContext(staticContext);
	const {
		onHide,
		title,
		date = "",
		img,
		detail = "",
		is_cancelled,
		seats_left,
		onReservation = () => {},
	} = props;
	const [isModalActive, toggleIsModalActive] = useToggle(false);
	const [, month = 1, day = 1] = date.split("-");

	const soldOut = seats_left <= 0;
	const isCancelled = is_cancelled === "1";

	if (isModalActive)
		return (
			<AttendanceModal
				{...props}
				addType
				services={state.allServices}
				show={isModalActive}
				onHide={toggleIsModalActive}
				onReservation={onReservation}
			></AttendanceModal>
		);

	return (
		<React.Fragment>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ opacity: "1" }}
			>
				<sEventDetailModal.Layout>
					<sEventDetailModal.Header>
						<sEventDetailModal.Title>{title}</sEventDetailModal.Title>
						<sEventDetailModal.Subtitle>
							{day} {months[month - 1].toUpperCase()}
						</sEventDetailModal.Subtitle>
						<sEventDetailModal.CloseBtn onClick={onHide}>
							<i className="fas fa-times"></i>
						</sEventDetailModal.CloseBtn>
					</sEventDetailModal.Header>

					<sEventDetailModal.Image src={img} />

					<sEventDetailModal.Row>
						<sEventDetailModal.Icon className="fas fa-bars" />
						<sEventDetailModal.Title>Detalle</sEventDetailModal.Title>
						<sEventDetailModal.Subtitle>{detail}</sEventDetailModal.Subtitle>
					</sEventDetailModal.Row>

					<sEventDetailModal.Row>
						<sEventDetailModal.Icon className="far fa-clock" />
						<sEventDetailModal.Title>fecha</sEventDetailModal.Title>
						<sEventDetailModal.Subtitle>{date}</sEventDetailModal.Subtitle>
					</sEventDetailModal.Row>

					<sEventDetailModal.Row>
						<sEventDetailModal.Icon className="fas fa-chair" />
						<sEventDetailModal.Title>
							Lugares Disponibles: {seats_left > 0 ? seats_left : 0}
						</sEventDetailModal.Title>
					</sEventDetailModal.Row>

					<sEventDetailModal.Footer>
						<Button
							disabled={soldOut || isCancelled}
							onClick={toggleIsModalActive}
						>
							Reserva tu lugar
						</Button>
					</sEventDetailModal.Footer>
				</sEventDetailModal.Layout>
			</Modal>
		</React.Fragment>
	);
}
