import React from "react";
import H1 from "../../elements/H1";
import Card from "../Card";

import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

const EventsLayout = styled.div`
	width: 100vw;
	height: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	box-sizing: border-box;
	padding: 2em 0 3em;
	box-sizing: border-box;

	p {
		width: 80%;
		display: inline-block;
		margin: 1em 0 4em;
	}
`;

const EventsImg = styled.img`
	display: block;
	margin: 0.5em 0;
`;

const CardsContainer = styled.div`
	display: grid;
	width: 100%;
	max-width: 1300px;
	grid-template-columns: repeat(3, 30%);
	gap: 1em;
	align-items: start;
	justify-content: center;

	@media ${device.tablet} {
		grid-template-columns: 80%;
		width: 90%;
	}

	@media ${device.mobileL} {
		grid-template-columns: 100%;
		gap: 2em;
	}
`;

export default function Eventos({ items = [] }) {
	return (
		<EventsLayout>
			{/*<H1>AMENIDADES PRINCIPALES</H1>*/}
			<EventsImg src="/assets/static/Icn_HojasViña_gris.png" alt="" />
			<p>
				Vive una experiencia inigualable y llena de confort en nuestras instalaciones.
			</p>
			<CardsContainer className="">
				{items
					.filter((i) => i.enabled === "1")
					.map((evento, i) => (
						
						<Card {...evento} key={i} type="services" />
					))}
			</CardsContainer>
		</EventsLayout>
	);
}
