import React from 'react';

import { OpinionCard } from './styles';

export default function Card(props) {
	const { imgUrl, name, ocupation, comment, rating } = props;
	let rate = [];
	for (let i = 0; i < rating; i++) {
		rate.push(<i className="fas fa-star" key={i} />);
	}
	return (
		<OpinionCard>
			<img src={imgUrl} alt="" className="OpinionCard_img" />
			<div className="OpinionCard_name">{name}</div>
			<div className="OpinionCard_ocupation">{ocupation}</div>
			<div className="OpinionCard_comment">{comment}</div>
			<div className="OpinionCard_rating">{rate}</div>
		</OpinionCard>
	);
}
