import React, { useState, memo } from "react";
import { useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal";
import Button from "../../elements/Button";
import Form from "react-bootstrap/Form";
import s from "./styles";

import api from "../../api";

function AttendanceModal(props) {
	const { id, title = "", date = new Date(), onHide, seats_left } = props;

	const { register, handleSubmit, errors } = useForm();
	const [confirmModal, setConfirmModal] = useState("");

	const onSubmit = async (data) => {
		const newAtt = { booking_id: id, ...data };
		console.log(newAtt);

		var form_data = new FormData();

		for (var key in newAtt) {
			form_data.append(key, newAtt[key]);
		}

		await api.Attendance.create(form_data);
		props.onReservation && props.onReservation(id);

		setConfirmModal("ok");
	};

	if (confirmModal) {
		return (
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				style={{ opacity: "1" }}
			>
				<s.Modal_body>
					<s.Modal_icon>
						<i class="fas fa-check"></i>
					</s.Modal_icon>
					<s.Modal_tile>Solicitud enviada</s.Modal_tile>
					<s.Modal_text>
						Gracias por su preferencia. <br />
						En breve nos pondremos en contacto para continuar con el proceso.{" "}
						<br />
					</s.Modal_text>
					<s.Modal_text_footer>
						Contamos con terminal física en nuestra oficina y terraza para el
						pago con tarjetas.
					</s.Modal_text_footer>
					<s.Modal_button onClick={onHide}>Aceptar</s.Modal_button>
				</s.Modal_body>
			</Modal>
		);
	}

	const selectItems = new Array(+seats_left).fill(null);

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			style={{ opacity: "1" }}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Reserva tu lugar
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form noValidate onSubmit={handleSubmit(onSubmit)}>
					<s.StepContent>
						<s.H1>Información del evento</s.H1>
						<s.EventDetail>
							<s.Subtitle>Evento:</s.Subtitle>
							<s.Title>{title}</s.Title>
							<s.Subtitle>Fecha:</s.Subtitle>
							<s.Title>{date}</s.Title>
						</s.EventDetail>

						<Form.Group>
							<s.H1>Información de Contacto</s.H1>
							<Form.Label>Nombre Completo *</Form.Label>
							<Form.Control
								name="name"
								defaultValue={""}
								ref={register({ required: true })}
							/>
							{errors.name?.type === "required" && (
								<s.Error type="invalid">El nombre es obligatorio.</s.Error>
							)}

							<Form.Label>Correo *</Form.Label>
							<Form.Control
								name="email"
								invalid
								defaultValue={""}
								ref={register({ required: true, pattern: /^\S+@\S+$/i })}
							/>
							{errors.email?.type === "required" && (
								<s.Error type="invalid">
									La cuenta de correo es obligatoria.
								</s.Error>
							)}
							{errors.email?.type === "pattern" && (
								<s.Error type="invalid">
									Ingrese una cuenta de correo válida.
								</s.Error>
							)}

							<Form.Label>Teléfono *</Form.Label>
							<Form.Control
								name="phone"
								defaultValue={""}
								ref={register({
									required: true,
									minLength: 6,
									pattern: /^[0-9]*$/i,
								})}
							/>
							{errors.phone?.type === "required" && (
								<s.Error type="invalid">El telefono es obligatorio.</s.Error>
							)}
							{errors.phone?.type === "minLength" ||
								(errors.phone?.type === "pattern" && (
									<s.Error type="invalid">
										Ingrese un número de teléfono válido.
									</s.Error>
								))}

							<Form.Label>Seleccione la cantidad de lugares</Form.Label>
							<Form.Control as="select" name="qty" ref={register}>
								{selectItems.map((_, index) => {
									const i = index + 1;
									return (
										<option key={i} value={i}>
											{i}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>
					</s.StepContent>

					<Modal.Footer className="justify-content-space-between">
						<Button onClick={onHide} type="button" outline>
							Cancelar
						</Button>
						<Button type="submit">Enviar</Button>
					</Modal.Footer>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default memo(AttendanceModal);
