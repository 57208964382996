import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import LoadingOverlay from "../components/LoadingOverlay";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Servicios from "../components/Servicios";
import Footer from "../components/Footer";


import { staticContext } from "../contexts/Static.context";

const LandingPage = styled.div`
	height: 100%;
	min-height: 100vh;
	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;
	display: grid;
	grid-template-rows: auto minmax(130vh, 1fr) auto;
	position: relative;
`;

export default function SingleService() {
	const { state, getStaticData } = useContext(staticContext);
	let params = useParams();

	useEffect(() => {
		console.log(state.services);
		if (!state.services[params.id]) {
			getStaticData({ serviceType: params.id, offers: false, reviews: false });
		}
	}, [getStaticData, params]);

	if (state.isLoading) {
		return <LoadingOverlay />;
	}

	const event = state.events.find((e) => e.id === params.id) || {};

	return (
		<LandingPage>
			<Header />
			<Banner messages={state.bannerMsgs} images={state.bannerImgs} />
			<Servicios
				items={state.services[params.id]}
				title={event && event.name}
				description={event && event.description}
			/>
			<i class="fa fa-xing" aria-hidden="true"></i>
			
			<Footer />
		</LandingPage>
	);
}

// import React, { useContext, useEffect } from "react";
// import styled from "styled-components";
// import { useParams } from "react-router-dom";

// import H1 from "../elements/H1";
// import Card from "../components/Card";

// import { device } from "../../src/helpers/mediaQueries";

// import LoadingOverlay from "../components/LoadingOverlay";
// import Header from "../components/Header";
// import Banner from "../components/Banner";
// import Eventos from "../components/Eventos";
// import EventosCoporativos from "../components/EventosCoporativos";
// import Servicios from "../components/Servicios";
// import Footer from "../components/Footer";
// import Vinedo from "../components/Vinedo";
// import { staticContext } from "../contexts/Static.context";


// const LandingPage = styled.div`
// 	height: 100%;
// 	min-height: 100vh;
// 	background-image: url("assets/static/fondo.png");
// 	background-color: #f9f8f7;
// 	background-repeat: repeat;
// 	background-size: 100%;
// 	display: grid;
// 	grid-template-rows: auto minmax(130vh, 1fr) auto;
// 	position: relative;
// `;

// const EventsLayout = styled.div`
// 	width: 100vw;
// 	height: max-content;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	text-align: center;
// 	box-sizing: border-box;
// 	padding: 2em 0 3em;
// 	box-sizing: border-box;

// 	p {
// 		width: 80%;
// 		display: inline-block;
// 		margin: 1em 0 4em;
// 	}
// `;

// const EventsImg = styled.img`
// 	display: block;
// 	margin: 0.5em 0;
// `;

// const CardsContainer = styled.div`
// 	display: grid;
// 	width: 100%;
// 	max-width: 1300px;
// 	grid-template-columns: repeat(3, 30%);
// 	gap: 1em;
// 	align-items: start;
// 	justify-content: center;

// 	@media ${device.tablet} {
// 		grid-template-columns: 80%;
// 		width: 90%;
// 	}

// 	@media ${device.mobileL} {
// 		grid-template-columns: 100%;
// 		gap: 2em;
// 	}
// `;

// export default function SingleService({ items = [] }) {
// 	const { state, getStaticData } = useContext(staticContext);
// 	let params = useParams();

// 	useEffect(() => {
// 		if (!state.services[params.id]) {
// 			getStaticData({ serviceType: params.id, offers: false, reviews: false });
// 		}
// 	}, [getStaticData, params]);

// 	if (state.isLoading) {
// 		return <LoadingOverlay />;
// 	}

// 	const event = state.events.find((e) => e.id === params.id) || {};
// 	// console.log(state.events);
// 	const condition = params.id;
	
// 	return (
// 		<LandingPage>
// 			<Header />
// 			<Banner messages={state.bannerMsgs} images={state.bannerImgs} />
// 			<div className="container-vinedo">
//                 <img className="img-vinedo" src="/assets/img/img_viñedo_01.png" alt="" />
//                 <div className="body-vinedo">
//                     <h1>RECORIDOS Y CATAS EN EL VIÑEDO</h1>
//                     <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//                     <br /><br />
// 					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
// 					<br /><br />
// 					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
// 					</p>
//                     <img className="img-cata" src="/assets/img/img_vino.png" alt="" />
//                 </div>
// 			</div>

// 		<EventsLayout>
// 			<H1>EVENTOS CORPORATIVOS</H1>
// 			<EventsImg src="/assets/static/Icn_HojasViña_gris.png" alt="" />
// 			<p>
// 				Vive una experiencia inigualable y llena de Confort en nuestras instalaciones.
// 			</p>
// 			<CardsContainer className="">
// 				{items
// 					.filter((i) => i.enabled === "1")
// 					.map((evento, i) => (
// 						<Card {...evento} key={i} type="services" />
// 					))}
// 			</CardsContainer>
// 		</EventsLayout>

// 		<EventosCoporativos items={state.events} />
// 		<Vinedo items={state.events} />

// 			<Footer />
// 		</LandingPage>
// 	);
// }
