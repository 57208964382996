import styled from "styled-components";

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: ${(props) => props.flexDirection || "column"};
	justify-content: ${(props) => props.justifyContent || "flex-start"};

	width: ${(props) => props.width || "100%"};
	padding: ${(props) => props.padding || "2em 1em 0"};
`;

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justifyContent || "flex-start"};

	padding: 1rem;
	margin: 0;

	text-align: center;
	cursor: pointer;
	background: none;
	border: none;

	&:hover {
		background: #eee;
	}

	&.active {
		background: var(--color-primary);
		color: white;
	}

	& i {
		margin: 0 1em;
	}

	${(props) => {
		if (props.danger) {
			return `
				background: none;
				border: 2px solid #d45d79;
				color: #d45d79;
				border-radius: 5px;
			`;
		}
		if (props.success) {
			return `
				color: white;
				background: #75b79e;
				border: 2px solid #75b79e;
				border-radius: 5px;
			`;
		}
	}}
`;

export const Wrapper = styled.div`
	width: 100%;
	max-width: 1300px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	justify-self: center;
`;
