import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import LoadingOverlay from '../components/LoadingOverlay';
import Header from '../components/Header';

import { staticContext } from '../contexts/Static.context';

const LandingPage = styled.div`
	height: 100%;
	min-height: 100vh;
	background-image: url('assets/static/fondo.png');
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;
	display: grid;
	grid-template-rows: 1fr;
	position: relative;
`;

const MainContent = styled.div`
	align-self: center;
	justify-self: center;
	text-align: center;
	color: var(--color-secondary);

	img {
		max-width: 35vw;
	}
`;

export default function SingleService() {
	const { state, getStaticData } = useContext(staticContext);

	useEffect(
		() => {
			if (state.events.length <= 0) {
				getStaticData();
			}
		},
		[ state, getStaticData ]
	);

	if (state.isLoading) {
		return <LoadingOverlay />;
	}

	return (
		<LandingPage>
			<Header />
			<MainContent>
				<img
					src="/assets/static/404.png"
					alt=""
					className="animated slower bounce infinite delay-1s"
				/>
				<h1>Hmmm!</h1>
				<h3>No encontramos lo que buscabas</h3>
			</MainContent>
		</LandingPage>
	);
}
