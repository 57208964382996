import React from 'react';
import axios from 'axios';
import Header from "../components/Header";
import Banner from "../components/Banner";
import Servicios from "../components/Servicios";
import Footer from "../components/Footer";

import styled from "styled-components";
import api from "../api";

const LandingPage = styled.div`
	height: 100%;
	min-height: 100vh;
	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;
	display: grid;
	grid-template-rows: auto minmax(130vh, 1fr) auto;
	position: relative;
`;


export default class Detalles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {service: {}}
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    api.Services.single(params.id).then(response => {
      console.log(response.data)
      this.setState({service: response.data})
    })
    
  }
	
  render() {
    return (
      <LandingPage>
			<Header />
			<Banner />
			<div className="container-detalles">
                 {/* <img className="img" src="/assets/img/img_viñedo_01.png" alt="" /> */}
				 <img className="img-detalles" src={this.state.service.imgUrl} alt="" />
                 <div className="body-detalles">
                    <h1>{this.state.service.name}</h1>
                    <p>{this.state.service.descripcion_long}</p>
                 </div>
 			</div>
			
			<Footer />
		</LandingPage>
			

    //   <ul>
	// 	{/* { this.state.services.map(service => <li>{service.imgBanner}</li>)} */}
	// 	{ this.state.services.map(service => <li>{service.type}</li>)}
    //     { this.state.services.map(service => <li>{service.title}</li>)}
	// 	{ this.state.services.map(service => <li>{service.description}</li>)}
	// 	{ this.state.services.map(service => <li>{service.img}</li>)}
	// 	{ this.state.services.map(service => <li>{service.descriptionLarge}</li>)}
    //   </ul>
    )

  }
}



// import React from "react";
// import axios from 'axios';
// import styled from "styled-components";
// // import H1 from "../../elements/H1";
// // import Card from "../Card";
// // import { device } from "../../helpers/mediaQueries";

// const DetallesLayout = styled.div`
// 	width: 100vw;
// 	height: max-content;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	text-align: center;
// 	box-sizing: border-box;
// 	padding: 2em 0 3em;
// 	box-sizing: border-box;

// 	p {
// 		width: 80%;
// 		display: inline-block;
// 		margin: 1em 0 4em;
// 	}
// `;

// export default function Detalles() {
// 	return (
// 		<DetallesLayout>
// 			<div className="container-vinedo">
//                 <img className="img-vinedo" src="/assets/img/img_viñedo_01.png" alt="" />
//                 <div className="body-vinedo">
//                     <h1>{title}</h1>
//                     <p>{descripLage}</p>
//                     <img className="img-cata" src="/assets/img/img_vino.png" alt="" />
//                 </div>
// 			</div>
// 		</DetallesLayout>
// 	);
// }
