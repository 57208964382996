// import React, {useState} from 'react';

// const catalogo = [
//     {
//         "nombre" : "Suite Carignan",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "3.400,00",
//         "precioHabitVD" : "3.700,00",
//         "precioHabitDescLJ" : "2.720,00",
//         "precioHabitDescVD" : "3.156,00",

//     },
//     {
//         "nombre" : "Suite Tempranillo",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "3.100,00",
//         "precioHabitVD" : "3.500,00",
//         "precioHabitDescLJ" : "2.480,00",
//         "precioHabitDescVD" : "3.004,00",
//     },
//     {
//         "nombre" : "Suite Chardonay",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "3.100,00",
//         "precioHabitVD" : "3.500,00",
//         "precioHabitDescLJ" : "2.480,00",
//         "precioHabitDescVD" : "3.004,00",
//     },
//     {
//         "nombre" : "Suite Merlot",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "3.100,00",
//         "precioHabitVD" : "3.500,00",
//         "precioHabitDescLJ" : "2.480,00",
//         "precioHabitDescVD" : "3.004,00",
//     },
//     {
//         "nombre" : "Suite Syrha",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "3.100,00",
//         "precioHabitVD" : "3.500,00",
//         "precioHabitDescLJ" : "2.480,00",
//         "precioHabitDescVD" : "3.004,00",
//     },
//     {
//         "nombre" : "Suite Cabernet Franc",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "3.400,00",
//         "precioHabitVD" : "3.700,00",
//         "precioHabitDescLJ" : "2.720,00",
//         "precioHabitDescVD" : "3.156,00",
//     },
    
//     {
//         "nombre" : "Suite Presidencial Champagne",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "4.500,00",
//         "precioHabitVD" : "4.800,00",
//         "precioHabitDescLJ" : "3.600,00",
//         "precioHabitDescVD" : "4.080,00",
//     },
//     {
//         "nombre" : "Suite Cabernet Suavignon",
//         "numHusped" : ["1","2","3","4"],
//         "precioHabitLJ" : "3.400,00",
//         "precioHabitVD" : "3.700,00",
//         "precioHabitDescLJ" : "2.720,00",
//         "precioHabitDescVD" : "3.156,00",
//     },
// ]

// console.log("catalogo", catalogo)

// function Menu(){

//     const [idHabitaciones, setIdHabitaciones] = useState(-1);   
//     const handlerCargarHabitaciones = function (e) {
//         const opcion = e.target.value;
//         console.log(opcion)

//         setIdHabitaciones(opcion);
//     }

//     return(
//         <>
//         <div className="row">
//             <div style={{marginTop:'2.5%', marginBottom:'2.5%'}} className="col-6">
//                 <h6 style={{textAlign:'center', justifyContent:'center'}}>Tipo de habitación</h6>
//                 <select style={{width:'100%'}} name="catalogo" id="selCatalogo" onClick={handlerCargarHabitaciones} required>
//                     <option value={-1}>Seleccione una opción: </option>
//                     {
//                         catalogo.map((item, i) => (
//                             <option key={"catalogo" + i} value={item.nombre}>{item.nombre}</option>              
//                         ))
                        
//                     }
//                     {/* <button type="submit">Prueba</button> */}
//                 </select>
//             </div>
//             <div style={{marginTop:'2.5%', marginBottom:'2.5%'}} className="col-6">
//                 <h6 style={{textAlign:'center', justifyContent:'center'}}>Número de huéspedes</h6>
//                     <select style={{width:'100%'}} name="habitaciones" id="selHabitacion">
                       
//                             {/* // idHabitaciones > -1 &&
                            
//                             // (
//                             //     catalogo[idHabitaciones].numHusped.map((item,i) => (
//                             //         
//                             //     ))
//                             // ) */}
//                             {/* <option key={"habitacion"+i} value={i}>{item}</option> */}
//                                         <option value="1">1</option>
//                                         <option value="2">2</option>
//                                         {/* <option value="3">3</option>
//                                         <option value="4">4</option> */}
                             
                        
//                     </select>
//             </div>
//         </div>

//        </>
//     )
// }

// export default Menu;

import React, {Fragment,useState} from 'react';

const catalogo = [
    {
        "nombre" : "Suite Carignan",
        "numHusped" : ["1","2"],
        "precioHabitLJ" : "3.400,00",
        "precioHabitVD" : "3.700,00",
        "precioHabitDescLJ" : "2.720,00",
        "precioHabitDescVD" : "3.156,00",

    },
    {
        "nombre" : "Suite Tempranillo",
        "numHusped" : ["1","2"],
        "precioHabitLJ" : "3.100,00",
        "precioHabitVD" : "3.500,00",
        "precioHabitDescLJ" : "2.480,00",
        "precioHabitDescVD" : "3.004,00",
    },
    {
        "nombre" : "Suite Chardonay",
        "numHusped" : ["1","2","3","4"],
        "precioHabitLJ" : "3.100,00",
        "precioHabitVD" : "3.500,00",
        "precioHabitDescLJ" : "2.480,00",
        "precioHabitDescVD" : "3.004,00",
    },
    {
        "nombre" : "Suite Merlot",
        "numHusped" : ["1","2"],
        "precioHabitLJ" : "3.100,00",
        "precioHabitVD" : "3.500,00",
        "precioHabitDescLJ" : "2.480,00",
        "precioHabitDescVD" : "3.004,00",
    },
    {
        "nombre" : "Suite Syrha",
        "numHusped" : ["1","2"],
        "precioHabitLJ" : "3.100,00",
        "precioHabitVD" : "3.500,00",
        "precioHabitDescLJ" : "2.480,00",
        "precioHabitDescVD" : "3.004,00",
    },
    {
        "nombre" : "Suite Cabernet Franc",
        "numHusped" : ["1","2"],
        "precioHabitLJ" : "3.400,00",
        "precioHabitVD" : "3.700,00",
        "precioHabitDescLJ" : "2.720,00",
        "precioHabitDescVD" : "3.156,00",
    },
    
    {
        "nombre" : "Suite Presidencial Champagne",
        "numHusped" : ["1","2"],
        "precioHabitLJ" : "4.500,00",
        "precioHabitVD" : "4.800,00",
        "precioHabitDescLJ" : "3.600,00",
        "precioHabitDescVD" : "4.080,00",
    },
    {
        "nombre" : "Suite Cabernet Suavignon",
        "numHusped" : ["1","2"],
        "precioHabitLJ" : "3.400,00",
        "precioHabitVD" : "3.700,00",
        "precioHabitDescLJ" : "2.720,00",
        "precioHabitDescVD" : "3.156,00",
    },
]

console.log("catalogo", catalogo)

function Menu(){

    const [idHabitaciones, setIdHabitaciones] = useState(-1);   
    const handlerCargarHabitaciones = function (e) {
        const opcion = e.target.value;
        console.log(opcion)
        setIdHabitaciones(opcion);
    }

    return(
        <Fragment>

        <div className="row">
            <div style={{marginTop:'2.5%', marginBottom:'2.5%'}} className="col-6">
                <h5 style={{textAlign:'center', justifyContent:'center', fontSize:18}}>Tipo de habitación</h5>
                <select style={{width:'100%'}} name="catalogo" id="selCatalogo" onChange={handlerCargarHabitaciones}>
                    <option value={-1}>Seleccione una opción: </option>
                    {
                        catalogo.map((item, i) => (
                            <option key={"catalogo" + i} value={i}>{item.nombre}</option>
                        ))
                    }
                </select>
            </div>
            <div style={{marginTop:'2.5%', marginBottom:'2.5%'}} className="col-6">
                <h5 style={{textAlign:'center', justifyContent:'center', fontSize:18}}>Número de huéspedes</h5>
                    <select style={{width:'100%'}} name="habitaciones" id="selHabitacion">
                        {
                            idHabitaciones > -1 &&
                            (
                                catalogo[idHabitaciones].numHusped.map((item,i) => (
                                    <option key={"habitacion"+i} value={item}>{item}</option>
                                ))
                            )
                        }
                    </select>
            </div>
        </div>

       </Fragment>
    )
}

export default Menu;