import styled from 'styled-components';
import { device } from '../../helpers/mediaQueries';

export const PromoCardLayout = styled.div`
	width: 100%;
	max-width: 600px;
	/* margin: 0 15px 0 0;
	padding: 0 15px 0 0; */
	box-sizing: border-box;

	img {
		width: 100%;
		height: 200px;
		object-fit:cover;
	}

	.PromoCard_detail {
		position: relative;
		display: grid;
		grid-template-columns: 3fr 1fr;
		column-gap: 1rem;

		padding: 2.5rem 1.5rem;

		text-align: center;
		background: #fff;

		&-data {
			padding-top: 1rem;
			padding: 0px;
			margin: 0px;
			box-sizing: border-box;

			text-align: left;

			h4 {
				margin: 0px;
				padding: 0px;

				color: #85496c;
				font-weight: bold;
				font-size: 24px;
			}

			p {
				margin: 14px 0 0 0;

				font-family: 'Cormorant Garamond', serif;
				font-weight: 400;
				font-size: 20px;
				line-height: 27px;
			}
		}

		&-tags {
			display: flex;
			flex-wrap: wrap;

			margin: 1.5rem 0 0;
			padding: 0px;
		}

		li {
			position: relative;

			margin: 0;
			margin-right: 1.3rem;

			list-style: none;

			&::before {
				position: absolute;
				top: 0;

				width: 1rem;
				left: -1rem;

				content: '-';
				text-align: center;
			}
		}
	}

	.PromoCard_promo {
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;

		p {
			margin: .5rem 0;
			color: #f3bb32;
			font-weight: bold;
		}

		&-price {
			background: #eee;
			padding: 1rem .5rem;

			h1 {
				font-size: 2rem;
			}

			h3 {
				color: gray;
				font-size: 1.5rem;
				text-decoration: line-through;
				font-weight: 400;
			}

			h6 {
				font-size: .7rem;
				color: gray;
				padding: 1rem 0;
			}
		}

		button {
			display: inline-block;

			width: 100%;
			padding: 10px 28px;

			color: #fff;
			text-transform: capitalize;
			font-size: 15px;

			background-color: #85496c;
			border: solid 1px #85496c;
			cursor: pointer;

			&:hover {
				background-color: #fff;
				border: solid 1px #fff;
				color: #85496c;
			}
		}
	}

	@media ${device.mobileL} {
		.PromoCard_detail {
			padding: 1px;
			grid-template-columns: 1fr;

			&-data {
				text-align: center;
			}

			&-tags {
				justify-content: center;
			}
		}

		.PromoCard_promo {
			&-price {
			padding: 5px 2px;
		}
	}
`;
