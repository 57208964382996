import styled from "styled-components";

export const Layout = styled.div`
	height: 100%;
	min-height: 100vh;
	background-image: url("assets/static/fondo.png");
	background-color: #f9f8f7;
	background-repeat: repeat;
	background-size: 100%;
	display: grid;
	grid-template: auto / 2fr 8fr;
	grid-template-areas: "buttons main";
	position: relative;
`;

export const ButtonsWrapper = styled.div`
	grid-area: buttons;
	display: flex;
	flex-direction: ${(props) => props.flexDirection || "column"};
	justify-content: ${(props) => props.justifyContent || "flex-start"};

	width: ${(props) => props.width || "100%"};
	padding: ${(props) => props.padding || "2em 1em 0"};
`;

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justifyContent || "flex-start"};

	padding: 1rem;
	margin: 0;

	text-align: center;
	cursor: pointer;
	background: none;
	border: none;

	&:hover {
		background: #eee;
	}

	&.active {
		background: var(--color-primary);
		color: white;
	}

	& i {
		margin: 0 1em;
	}

	${(props) => {
		if (props.danger) {
			return `
				background: none;
				border: 2px solid #d45d79;
				color: #d45d79;
				border-radius: 5px;
			`;
		}
		if (props.success) {
			return `
				color: white;
				background: #75b79e;
				border: 2px solid #75b79e;
				border-radius: 5px;
			`;
		}
	}}
`;

export const MainContent = styled.div`
	grid-area: main;
	align-self: start;
	justify-self: center;

	padding: 1rem;
	width: 100%;

	text-align: center;
	color: var(--color-secondary);

	img {
		max-width: 35vw;
	}
`;

export const MainContentHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: max-content;
	margin: 1em 0;
	padding: 0 1em;
	border-bottom: 2px solid #eee;

	h1 {
		max-width: 70%;

		font-size: 25px;
		color: var(--color-secondary);
	}
`;

export const Subtitle = styled.h2`
	font-size: 2rem;
	color: #a49d9d;
	margin-top: ${(props) => props.mt || "4rem"};
	text-align: center;
`;

export const Overly = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(0, 0, 0, 0.4);
`;

export const OverlyContent = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 50%;
	height: 50%;
	padding: 3rem;

	background: white;
	border-radius: 3px;

	button {
		display: inline-block;

		width: max-content;
		padding: 1em 2em;

		cursor: pointer;
	}

	input[type="text"] {
		width: 100%;
		padding: 1em 0.5rem;
		margin: 2em;
	}

	input[type="file"] {
		text-align: center;
		width: 100%;
	}
`;
