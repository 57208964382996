import React from "react";

import sEventCard from "./EventCard.styles";

const months = [
	"ene",
	"feb",
	"mar",
	"abr",
	"may",
	"jun",
	"jul",
	"ago",
	"sep",
	"oct",
	"nov",
	"dic",
];

export default function EventCard({
	onClick = () => {},
	id,
	seats_left,
	is_cancelled,
	title,
	date = "",
	img,
}) {
	const [, month = 1, day = 1] = date.split("-");
	const soldOut = seats_left <= 0;
	const isCancelled = is_cancelled === "1";

	function handleClick() {
		onClick({ id });
	}

	return (
		<sEventCard.Layout onClick={handleClick}>
			<sEventCard.Image src={img}></sEventCard.Image>
			<sEventCard.Content soldOut={soldOut || isCancelled}>
				<sEventCard.Date>
					<sEventCard.Day>{day}</sEventCard.Day>
					<sEventCard.Month>{months[month - 1]}</sEventCard.Month>
				</sEventCard.Date>
				<sEventCard.Detail>
					<sEventCard.Title>{title}</sEventCard.Title>
					{isCancelled && <sEventCard.SoldOut>Cancelado</sEventCard.SoldOut>}
					{soldOut && <sEventCard.SoldOut>Lleno</sEventCard.SoldOut>}
				</sEventCard.Detail>
			</sEventCard.Content>
		</sEventCard.Layout>
	);
}
