import React from "react";

import { ButtonsWrapper, Button } from "./styled.components";

export default function ButtonsBar({
	galleries,
	activeGallery,
	onChangeGallery,
}) {
	return (
		<ButtonsWrapper flexDirection="row">
			{galleries.map((g, i) => (
				<Button
					key={g.id}
					onClick={(e) => onChangeGallery(i)}
					className={i === activeGallery ? "active" : ""}
					justifyContent="center"
				>
					{g.name}
				</Button>
			))}
		</ButtonsWrapper>
	);
}
