import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

export const HeaderBar = styled.header`
	position: fixed;
	top: 0;
	z-index: 1000;
	width: 100%;
	display: grid;
	grid-template-columns: max-content auto auto max-content;
	box-sizing: border-box;

	color: #85496cd4;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 16px;
	font-family: "Raleway", sans-serif;
	border-left: solid 1px #cccccc;

	background: white;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.17);

	@media ${device.tablet} {
		background: none;
		box-shadow: none;
	}
`;

export const HeaderLogo = styled.img`
	width: 150px;
	height: 50px;
	float: left;
	margin: 9px 0 0 0;
	padding: 0 15px;

	@media ${device.tablet} {
		display: none;
	}
`;

export const Nav = styled.nav`
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;

	/* Small devices */
	@media ${device.tablet} {
		display: none;
	}
`;

export const HeaderReservation = styled.div`
	display: flex;
	align-items: center;
	color: #fff;
	background: #85496c;
	font-weight: 500;
	font-family: "Exo", sans-serif;
	transition: 0.4s ease;
	cursor: pointer;

	a {
		color: inherit;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 18px;
		padding: 22px 25px;
	}

	i {
		color: inherit;
		margin-left: 1.5rem;
	}

	&:hover {
		background: #fff;
		color: #85496c;
	}

	/* Small devices */
	@media ${device.laptop} {
		display: none;
	}
`;

// Menu

export const Li = styled.li`
	position: relative;
	padding: 1.5em 1em;

	list-style-type: none;

	&:hover ul {
		display: inline-block;
	}

	a {
		display: block;
		width: max-content;
		height: 100%;

		text-decoration: none;
		text-transform: uppercase;
		color: #85496cd4;

		&:hover {
			color: #85496c;
		}

		&.active::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 5px;
			background-color: var(--color-primary);
		}
	}
`;

export const A = styled.a`
	display: block;
	width: max-content;
	height: 100%;

	text-decoration: none;
	text-transform: uppercase;
	color: #141414;

	transition: 0.4s ease-in;

	&:hover {
		color: #85496c;
	}
`;

// Submenu

export const SubUl = styled.ul`
	display: none;
	position: absolute;
	top: 100%;
	left: 0%;
	width: max-content;
	margin: 0;
	padding: 0;
	opacity: 0.9;

	list-style-type: none;
	background: white;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.17);
	transition: 0.4s ease-in;

	&:hover {
		display: inline-block;
		opacity: 1;
	}
`;

export const SubLi = styled.li`
	display: block;
	min-width: max-content;

	list-style-type: none;
	text-align: center;
	border-bottom: 1px solid #eee;

	transition: 0.4s ease;

	&:hover {
		background: #85496c;
		color: #ffffff;
	}

	&:hover a {
		color: #fff;
	}

	& a {
		width: 100%;
		height: 100%;
		padding: 2em;

		text-decoration: none;
		text-transform: uppercase;
		color: inherit;
	}
`;

export const SubA = styled.a`
	width: 100%;
	height: 100%;

	text-decoration: none;
	text-transform: uppercase;
	color: inherit;
`;
