import styled from "styled-components/macro";

const Layout = styled.div`
	display: grid;
	grid-template: max-content max-content 1fr / 1fr;
	gap: 1rem;

	width: 100%;
	max-width: 1024px;
	padding: 1rem;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Date = styled.h2`
	color: #c6c6c6;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 36px;
`;

const ButtonsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;

	width: max-content;
`;

const Button = styled.button`
	width: 2.5rem;
	height: 2.5rem;
	border: 1px solid #c6c6c6;
	border-radius: 50%;
	background: white;

	color: #c6c6c6;
	font-size: 1rem;
	font-weight: bold;

	outline: none;
	cursor: pointer;

	&::before {
		content: ${(props) => (props.left ? "'<'" : "'>'")};
	}

	&:hover {
		background: #c6c6c6;
		color: white;
	}
`;

const CardsContainer = styled.div`
	display: grid;
	grid-template: auto / repeat(2, 1fr);
	gap: 1rem;

	width: 100%;
	max-width: 1024px;

	& > *:nth-child(odd) {
		justify-self: start;
	}
	& > *:nth-child(even) {
		justify-self: end;
	}
`;

const Loading = styled.div`
	width: 100%;
	height: 10px;

	background: transparent;

	&::before {
		content: "";
		display: block;

		width: 10%;
		height: 100%;

		background: var(--color-primary);
	}
`;

const Subtitle = styled.h2`
	color: #c6c6c6;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 24px;
`;

export default {
	Layout,
	Header,
	Date,
	CardsContainer,
	ButtonsContainer,
	Button,
	Loading,
	Subtitle,
};
