import React from "react";
import {
	OverlyContent,
	Overly,
	ButtonsWrapper,
	Subtitle,
	Button,
} from "./styled.components";

export default function Form({ onSubmit, toggle, title, galleryName }) {
	return (
		<Overly>
			<OverlyContent onSubmit={onSubmit}>
				<Subtitle mt="0">{title}</Subtitle>
				<input
					type="text"
					name="name"
					placeholder={galleryName || "Titulo de galería"}
				/>
				<input type="file" name="imgs" id="" multiple />
				<ButtonsWrapper
					padding=" 4em 0 0"
					flexDirection="row"
					justifyContent="space-between"
				>
					<Button type="button" onClick={toggle} danger>
						Cancelar
					</Button>
					<Button success>Guardar</Button>
				</ButtonsWrapper>
			</OverlyContent>
		</Overly>
	);
}
