import React from "react";

import { CardLayout } from "./styles";

export default function Card(props) {
	const { title, detail = "", date, img } = props;
	return (
		<CardLayout>
			<img src={img} alt="" />
			<div className="Card_content">
				<h1>{title}</h1>
				<p>{detail.substring(0, 180) + "..."}</p>
				<h6>{date}</h6>
			</div>
		</CardLayout>
	);
}
