import styled from "styled-components";

export const ImagesContainer = styled.div`
	display: grid;
	grid-template: auto / repeat(4, 1fr);
	gap: 1rem;
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
`;

export const DeleteIcon = styled.i`
	position: absolute;
	top: 5%;
	right: 10%;

	width: 20px;
	height: 20px;

	opacity: 0;
	color: white;
	font-size: 2em;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
	cursor: pointer;

	&&:hover {
		transform: scale(1.1);
	}
`;

export const ImageWrapper = styled.div`
	position: relative;

	width: 100%;
	height: 100%;
	min-width: 100px;
	min-height: 100px;

	background: #eee;

	&:hover ${DeleteIcon} {
		opacity: 1;
	}
`;
