import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import api from "../../api";
import s from "./styles";
import useToggle from "../../hooks/useToggle";
import EventModal from "../EventModal";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/list/main.css";

export default function Calendar() {
	const [events, setEvents] = useState([]);
	const [services, setServices] = useState([]);
	const [activeEvent, setActiveEvent] = useState({});
	const [isModalActive, toggleIsModalActive] = useToggle(false);
	const [isModalAddActive, toggleIsModalAddActive] = useToggle(false);

	useEffect(() => {
		async function getEvents() {
			const [bookingsResp, servicesResp] = await Promise.all([
				api.Bookings.index(),
				api.Services.index(),
			]);

			setEvents(
				bookingsResp.data.map((event) => ({
					...event,
					editable: true,
					textColor: event.enabled === "1" ? "#fff" : "#85496c",
					backgroundColor: event.enabled === "1" ? "#85496c" : "#fff",
					borderColor: "#85496c",
				}))
			);

			setServices(servicesResp.data);
		}
		getEvents();
	}, [setEvents]);

	function showModal({ event }) {
		const newEvent = {
			id: event.id,
			title: event.title,
			date: event.start,
			...event.extendedProps,
		};
		setActiveEvent(newEvent);
		toggleIsModalActive();
	}

	async function saveEvent(event) {
		const formData = new FormData();

		Object.keys(event).forEach((key) => {
			formData.append(key, event[key]);
		});

		const response = await api.Bookings.update(event.id, formData);

		// update events with response
		const respEvent = response.data;
		const eventIndex = events.findIndex((event) => event.id === respEvent.id);

		const newEvent = {
			...events[eventIndex],
			...respEvent,
			textColor: respEvent.enabled === "1" ? "#fff" : "#85496c",
			backgroundColor: respEvent.enabled === "1" ? "#85496c" : "#fff",
		};

		const newEvents = [
			...events.slice(0, eventIndex),
			newEvent,
			...events.slice(eventIndex + 1),
		];

		setEvents(newEvents);
	}

	async function createEvent(event) {
		const formData = new FormData();

		Object.keys(event).forEach((key) => {
			formData.append(key, event[key]);
		});

		const response = await api.Bookings.create(formData);

		// update events with response
		const respEvent = response.data;

		const newEvent = {
			...respEvent,
			editable: true,
			textColor: respEvent.enabled === "1" ? "#fff" : "#85496c",
			backgroundColor: respEvent.enabled === "1" ? "#85496c" : "#fff",
			borderColor: "#85496c",
		};

		const newEvents = [...events, newEvent];

		setEvents(newEvents);
	}

	return (
		<s.Calendar>
			<s.Button onClick={toggleIsModalAddActive}>Agregar Evento</s.Button>
			<FullCalendar
				defaultView="dayGridMonth"
				plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
				header={{ center: "dayGridMonth,listWeek", right: "today prev,next" }}
				weekends={true}
				events={events}
				locales={[esLocale]}
				locale="es" // the initial locale. of not specified, uses the first one
				eventDrop={(info) => {
					const date = info.event.start.toISOString().slice(0, 10);
					saveEvent({ id: info.event.id, date });
					// const formData = new FormData();
					// formData.append("date", date);
					// api.Bookings.update(info.event.id, formData);
				}}
				eventClick={showModal}
			/>
			{isModalActive && (
				<EventModal
					{...activeEvent}
					services={services}
					show={isModalActive}
					onHide={toggleIsModalActive}
					onSave={saveEvent}
				></EventModal>
			)}
			{isModalAddActive && (
				<EventModal
					services={services}
					show={isModalAddActive}
					onHide={toggleIsModalAddActive}
					onSave={createEvent}
				></EventModal>
			)}
		</s.Calendar>
	);
}
