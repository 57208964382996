import React, { useReducer, useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";

import sGridEventCard from "./GridEventCard.styles";
import EventCard from "../EventCard";
import EventDetailModal from "../EventDetailModal";
import Select from "../../elements/Select";

import useToggle from "../../hooks/useToggle";
import months from "../../helpers/monthsEsp";

import {
	dateReducer,
	initialState,
	fetchMonthEvents,
	subtractOneMonth,
	addOneMonth,
} from "./GridEventCard.reducer";

export default function GridEventCard({ filterOptions, defaultFilter }) {
	const [state, dispatch] = useReducer(dateReducer, initialState);
	const [actiteEvent, setActiveEvent] = useState({});
	const [showModal, toggleShowModal] = useToggle(false);
	const [filter, setFilter] = useState(defaultFilter);

	useEffect(() => {
		fetchMonthEvents(
			dispatch,
			state.date.getMonth() + 1,
			state.date.getFullYear(),
			filter
		);
	}, [state.date, filter]);

	function handleCardClick(e) {
		const event = state.events.find((event) => event.id === e.id);

		if (event) {
			setActiveEvent(event);
			toggleShowModal();
		}
	}

	function handleFilterChange(e) {
		if (Array.isArray(e)) {
			const ids = e.map((item) => item.value);
			const idString = ids.join(",");
			setFilter(idString);
		} else {
			setFilter("");
		}
	}

	const defaultFilterIndex =
		filterOptions.findIndex((item) => item.value == defaultFilter) || 0;

	return (
		<React.Fragment>
			<sGridEventCard.Layout>
				<sGridEventCard.Header>
					<sGridEventCard.Date>
						{months[state.date.getMonth()]}, {state.date.getFullYear()}
					</sGridEventCard.Date>
					<sGridEventCard.ButtonsContainer>
						<sGridEventCard.Button
							left
							disabled={state.isLoading}
							onClick={() => dispatch(subtractOneMonth())}
						></sGridEventCard.Button>
						<sGridEventCard.Button
							right
							disabled={state.isLoading}
							onClick={() => dispatch(addOneMonth())}
						></sGridEventCard.Button>
					</sGridEventCard.ButtonsContainer>
				</sGridEventCard.Header>
				<Select
					isMulti
					options={filterOptions}
					defaultIndex={defaultFilterIndex}
					onChange={handleFilterChange}
				></Select>
				{state.isLoading ? (
					<BarLoader width="100%" color="#85496c"></BarLoader>
				) : (
					<sGridEventCard.CardsContainer>
						{state.events && state.events.length ? (
							state.events.map((event) => (
								<EventCard
									key={event.id}
									onClick={handleCardClick}
									{...event}
								></EventCard>
							))
						) : (
							<sGridEventCard.Subtitle>
								Nuevos eventos próximamente
							</sGridEventCard.Subtitle>
						)}
					</sGridEventCard.CardsContainer>
				)}
			</sGridEventCard.Layout>
			{showModal && (
				<EventDetailModal
					show={showModal}
					onHide={toggleShowModal}
					{...actiteEvent}
				/>
			)}
		</React.Fragment>
	);
}
