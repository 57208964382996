import React, { useState, memo } from "react";
import { Zoom } from "react-slideshow-image";
import styled from "styled-components";
import { device } from "../../helpers/mediaQueries";

import Form from "./Form";

const BannerLayout = styled.div`
	height: 95vh;
	width: 99vw;
	position: relative;

	.hide {
		opacity: 0;
	}

	div.react-slideshow-container {
		position: relative;
	}

	div.react-slideshow-container::after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: "";
		background: rgb(0, 0, 0);
		background: linear-gradient(
			360deg,
			rgba(0, 0, 0, 0.7) 0%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(133, 73, 108, 1) 100%
		);
		z-index: 2;
	}

	.react-slideshow-container div.nav {
		background: rgba(133, 73, 108, 0.4) 100%;
	}

	.react-slideshow-container div.nav span {
		border-color: #ffffff;
	}

	.react-slideshow-container div.nav:hover {
		background: rgba(255, 255, 255, 0.4) 100%;
	}

	.react-slideshow-container div.nav span:hover {
		border-color: rgba(133, 73, 108, 1) 100%;
	}

	.zoom-wrapper div {
		height: 95vh;
	}

	.zoom-wrapper img {
		object-fit: cover;
	}

	@media ${device.laptop} {
		height: 90vh;
		width: 99vw;

		.zoom-wrapper div {
			height: 90vh;
		}
	}

	@media ${device.tablet} {
		width: 100vw;
		margin-bottom: 200px;
	}
`;

const BannerText = styled.div`
	display: grid;
	grid-template: minmax(max-content, 45vh) min-content auto / 1fr;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	padding: 2rem 0;

	color: white;

	img {
		max-width: 255px;
		justify-self: center;
		transition: 1s ease;
	}

	h1 {
		justify-self: center;
		align-self: center;
		font-size: 2rem;
		font-weight: bold;
		transition: 1s ease;
		text-transform: uppercase;
		text-shadow: rgba(0, 0, 0, 0.8) 5px 5px 10px;
	}

	h2 {
		color: white;
		margin: 1rem 0 0;
		justify-self: center;
		align-self: start;
		font-size: 2rem;
		font-weight: bold;
		transition: 1s ease;
		text-shadow: rgba(0, 0, 0, 0.8) 5px 5px 10px;
		font-family: "Cormorant Garamond", serif;
	}

	@media ${device.laptop} {
		grid-template: minmax(max-content, 30vh) min-content auto / 1fr;
	}

	@media ${device.tablet} {
		h1 {
			font-size: 1.5rem;
		}
	}

	@media ${device.mobileL} {
		img {
			max-width: 155px;
		}
		h1 {
			text-align: center;
			font-size: 1rem;
		}

		h2 {
			font-size: 0.8rem;
		}
	}
`;

const imagesDefault = [
	"/assets/static/img_render_01-min.png",
	// "/assets/img/eventos_sociales.png",
	// "/assets/img/eventos_corporativos.png",

	
	// "/assets/static/img_viñedo_001-min.png",
	// "/assets/static/img_viñedo_002-min.png",
];

const properties = {
	duration: 7500,
	transitionDuration: 600,
	infinite: true,
	indicators: false,
	scale: 0.4,
	arrows: false,
};

const messagesDefault = [
	{
		title: "Somos la mejor opción para tus eventos",
		subtitle: "The Best Hotel Reservation",
	},
	{
		title: "Conoce nuestros hermosos viñedos",
		subtitle: "The Best Hotel Reservation",
	},
	{
		title: "Conoce nuestros hermosos viñedos 3",
		subtitle: "The Best Hotel Reservation",
	},
];

function Banner({
	images = imagesDefault,
	messages = messagesDefault,
	services = [],
}) {
	const [sliderIndex, setSliderIndex] = useState(0);
	const [isAnimated, setIsAnimated] = useState(true);

	function handleNext(newIndex) {
		setSliderIndex(newIndex);
		setIsAnimated(false);

		setTimeout(() => {
			setIsAnimated(true);
		}, 500);
	}

	return (
		<BannerLayout>
			<BannerText>
				<img
					src="/assets/img/logo_quinta_fabiana_nuevo.png"
					className="animated zoomIn delay-1s"
					alt=""
				/>
				<h1 className={`${isAnimated ? "animated zoomIn delay-2s" : "hide"}`}>
					{messages[sliderIndex].title}
				</h1>
				<h2 className={`${isAnimated ? "animated zoomIn delay-3s" : "hide"}`}>
					{messages[sliderIndex].subtitle}
				</h2>
			</BannerText>
			<Zoom
				{...properties}
				onChange={(oldIndex, newIndex) => handleNext(newIndex)}
			>
				{images.map((each, index) => (
					<img
						key={index}
						style={{ width: "100vw", height: "100%", objectFit: "none" }}
						src={each}
						alt=""
					/>
				))}
			</Zoom>
			<Form services={services} />
		</BannerLayout>
	);
}

export default memo(Banner);
